import { Avatar, Button, CircularProgress, Container, CssBaseline, Grid, TextField, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import CustomizedSnackbars from "../../components/material-snackbars/index.js";
import { AbilityContext } from '../../config/ability-context';
import { browserHistory } from '../../helpers/history';
import Api from "../../services/api";
import axios from 'axios';
import { Overlay } from "../../styles/global.js";
import { Styles } from '../../styles/material-styles';
import { useSearchParams } from "react-router-dom";
import queryString from 'query-string';
//import {decode as base64_decode, encode as base64_encode} from 'base-64';
import qs from 'qs';

class Authorize extends Component {

  constructor(props) {
    super(props);

    const { match } = this.props;
    let search = this.props.location.search;

    let params = new URLSearchParams(search);
    let param_code = params.get('code');
    let param_state = params.get('state');
    let url_redirect = params.get('redirect');
    let scope = params.get('scope');
    let uuid = params.get('uuid');
    localStorage.setItem('govbr_code', `${param_code}`)



    this.itemInitialState = {
      param_code: param_code,
      param_state: param_state,
      url_redirect: url_redirect,
      scope: scope,
      uuid: uuid,
    }; 

    this.state = {
      item: this.itemInitialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
    };

    this.auth = this.auth.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
  }

  auth = async () => {
    this.setState({ loading: true });
    let govbr_state = localStorage.getItem('govbr_state');
    
    //alert(govbr_state);
    //alert(this.state.item.param_state);
    //alert(this.state.item.param_code);
    //alert(this.state.item.url_redirect);
   // alert(this.state.item.scope);
    //alert(this.state.item.uuid);
    
    
    try {

        var ApiAxios = axios.create({
          baseURL: Api.apiurl(),
        });
        ApiAxios.defaults.headers.post['Accept'] = 'application/json';
        ApiAxios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

        if(this.state.item.param_code != null && this.state.item.scope != null ){
                  const data = {};
                  data.code=this.state.item.param_code;
                  data.scope=this.state.item.scope;
                  data.uuid=this.state.item.uuid;
                  

                  let config = {
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Accept" : "application/json",
                        "Access-Control-Allow-Origin":"*",
                      }
                  };        
                  var u = Api.apiurl(); 
  
                  alert(u); 
                  ApiAxios.post(u+"/gmail/oauth2",qs.stringify(data), config)
                  .then(result => {
                      console.log(JSON.stringify(result));
      
                      this.setState({
                          loading: false,
                          openNotification: true,
                          notificationVariant: "success",
                          notificationMessage: "Autenticação realizada com sucesso!",
                      });
      
                  })
                  .catch(err => {
                      this.setState({
                          loading: false,
                          openNotification: true,
                          notificationVariant: "error",
                          notificationMessage: JSON.stringify(err.response)
                      });
                  });

        }
        else{
          if(govbr_state == this.state.item.param_state){


        
              let dataSet = new FormData();
              let secret="guvhEYTAySOTrjINWWK44cdiNJKUSoGijtaeyKONjzcM3HT70xWaRWeV4Mrb3l1-T24Rlmp91TNDYyIsmXzjVg";
              let client_id="speedmais.homologa.2do.mexx.ai";
          
              let redirecturi = 'https%3A%2F%2Fspeedmais.homologa.2do.mexx.ai%2Fpt%2Fauthorize';
              redirecturi = 'https://speedmais.homologa.2do.mexx.ai/pt/authorize';
              //redirecturi='https://speedmais.homologa.2do.mexx.ai';
              let codeverifier = localStorage.getItem('govbr_codeverifier');
              let code_challenge = localStorage.getItem('govbr_code_challenge');
              
              if(this.state.item.url_redirect != null){
                redirecturi = this.state.item.url_redirect;
              }

              dataSet.append('grant_type', "authorization_code");
              dataSet.append('code', this.state.item.param_code);
              dataSet.append('redirect_uri', redirecturi);
              dataSet.append('code_verifier', codeverifier);
              //Informação codificada em Base64, no seguinte formato: CLIENT_ID:CLIENT_SECRET (senha de acesso do serviço consumidor)(utilizar codificador para Base64 site externo para gerar codificação). A palavra Basic deve está antes da informação.

              let token = Buffer.from(client_id+':'+secret).toString('base64');
              let config = {
                  headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                      "Authorization" : "Basic " + token,
                      //"Access-Control-Allow-Origin":"*",
                  }
              };        
              const data = {};
              data.grant_type="authorization_code";
              data.code=this.state.item.param_code;
              data.redirect_uri=redirecturi;
              data.code_verifier=codeverifier;
              

              let url="https://sso.staging.acesso.gov.br";
              console.log(dataSet);
              console.log(config);
              await ApiAxios.post(url+"/token", qs.stringify(data), config)
              .then(result =>  {
                  console.log(JSON.stringify(result));
                  localStorage.setItem('govbr_result', result);
                  this.setState({
                      loading: true,
                      openNotification: true,
                      notificationVariant: "info",
                      notificationMessage: "Autenticação realizada, recuperando autorização...",
                  });

                  /*
                      {
                              "access_token": "(Token de acesso a recursos protegidos do autenticador, bem como serviços do Login Único.)",
                              "id_token": "(Token de autenticação com informações básicas do usuário.)",
                              "token_type": "(O tipo do token gerado. Padrão: Bearer)",
                              "expires_in": "(Tempo de vida do token em segundos.)"
                      }                
                  */ 
                  let config = {
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Accept" : "application/json",
                          //"Access-Control-Allow-Origin":"*",
                      }
                  };        
      

                  ApiAxios.get(url+"/jwk", config)
                      .then(result => {
                          console.log(JSON.stringify(result));
                          localStorage.setItem('govbr_claims', result);
          
                          this.setState({
                              loading: false,
                              openNotification: true,
                              notificationVariant: "success",
                              notificationMessage: "Autenticação realizada com sucesso!",
                          });
          
                      })
                      .catch(err => {
                          this.setState({
                              loading: false,
                              openNotification: true,
                              notificationVariant: "error",
                              notificationMessage: JSON.stringify(err.response)
                          });
                  });


            })
              .catch(err => {
                  this.setState({
                      loading: false,
                      openNotification: true,
                      notificationVariant: "error",
                      notificationMessage: JSON.stringify(err.response)
                  });
              });
          }
          else{
              this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "error",
                  notificationMessage: "Acesso não permitido."
              });

          }

        }

    } catch (err) {
      console.error(err);

    } finally {
      this.setState({ loading: false });
    }
  }
  closeNotification() {
    this.setState({ openNotification: false });
  }

  async componentDidMount() {
    await this.auth();
  }

  render() {

    return (
      <div>
         <main>
          <Container component='main' maxWidth='md'>
            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            {
              this.state.openNotification && (
                <CustomizedSnackbars
                  variant={this.state.notificationVariant}
                  message={this.state.notificationMessage}
                  isOpen={this.state.openNotification}
                  toClose={this.closeNotification}
                />
              )
            }          
          </Container>
         </main>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userSession: state.userSession,
  match: state.match
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(Authorize)));

Authorize.contextType = AbilityContext;