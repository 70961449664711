import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, IconButton, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Service from '../../services/satisfactionsurveyquestionstype.service.';
import * as componentActions from '../../redux/actions/satisfactionsurveys';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage, injectIntl } from 'react-intl';
import Title from '../../components/title-name';
import { PapiroConsole } from '../../utils/papiroConsole';
import SimpleCheckBoxDynamic from "../../components/checkbox dynamic/check";
import SimpleCheckBox from "../../components/checkbox/check";
import Box from '@mui/material/Box';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import DialogGeneral from '../../components/dialog-general';
import SimpleSelect from '../../components/select/simple-dynamic';

class SatisfactionSurveyEdit extends Component {

  constructor(props) {
    super(props);
    const { userSession, satisfactionsurveys } = this.props;

    PapiroConsole.log(satisfactionsurveys)
    PapiroConsole.log("satisfactionsurveys")

    this.globalAdm = false;
    this.isClient = true;
    if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    if (!satisfactionsurveys || (satisfactionsurveys && !satisfactionsurveys.satisfactionsurvey)) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/calleds`
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    }
    );





    this.initialState = {
      updatedUser: userSession.user.id,
      id: satisfactionsurveys.satisfactionsurvey.id,
      name: satisfactionsurveys.satisfactionsurvey.name,
      active: satisfactionsurveys.satisfactionsurvey.active,
      satisfactionsurveyquestions: satisfactionsurveys.satisfactionsurvey.satisfactionsurveyquestions ? satisfactionsurveys.satisfactionsurvey.satisfactionsurveyquestions.sort((a, b) => a.id - b.id) : []

    };
    this.state = {
      satisfactionsurvey: this.initialState,
      satisfactionSurveyQuestionTypeList: [],
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      DialogRemoveState: false,
      DialogAddIndcsatisfiedServiceIndex: false,
      setIndexToRemove: null,
      setInputFieldToRemove: null,
      setIndexToAddIndcsatisfiedServiceQuestion: null,
      setInputFieldToAddIndcsatisfiedServiceQuestion: null,



    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.changeValuesSurveyQuestions = this.changeValuesSurveyQuestions.bind(this);
    this.openOrCloseRemoveModal = this.openOrCloseRemoveModal.bind(this);
    this.openOrCloseDialogAddIndcsatisfiedServiceIndexModal = this.openOrCloseDialogAddIndcsatisfiedServiceIndexModal.bind(this);

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {

    this.getSatisfactionSurveyQuestionsType()
  };

  openOrCloseRemoveModal = (index, inputField) => {

    PapiroConsole.log(index, inputField)

    //elemento já salvo
    if (index != null && inputField.id && inputField.id != 0) {
      this.setState({ DialogRemoveState: true, setIndexToRemove: index, setInputFieldToRemove: inputField.id })
    }
    //elemento não salvo
    else if (index != null) {
      this.handleRemoveFields(index)
    }
    //fechando modal
    else {
      this.setState({ DialogRemoveState: false, setIndexToRemove: null, setInputFieldToRemove: null })
    }


  }

  openOrCloseDialogAddIndcsatisfiedServiceIndexModal = (index, inputField) => {


    if (this.state.DialogAddIndcsatisfiedServiceIndex == false) {
      this.setState({ DialogAddIndcsatisfiedServiceIndex: !this.state.DialogAddIndcsatisfiedServiceIndex, setIndexToAddIndcsatisfiedServiceQuestion: index, setInputFieldToAddIndcsatisfiedServiceQuestion: inputField })
    }
    else {
      this.setState({ DialogAddIndcsatisfiedServiceIndex: !this.state.DialogAddIndcsatisfiedServiceIndex, setIndexToAddIndcsatisfiedServiceQuestion: null, setInputFieldToAddIndcsatisfiedServiceQuestion: null })
    }




  }




  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      satisfactionsurvey: {
        ...prevState.satisfactionsurvey,
        [name]: value
      }
    }));
  };

  inputQuestionsValidation(inputField, index) {

    let inputFieldValidation = true
    let iterator = 0;


    if (inputField.name == '' || inputField.name == null || inputField.name == undefined) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: `O campo Nome da pergunta precisa ser preenchido na Pergunta da pesquisa de satisfação ${index + 1}`

      });
      return false;
    }

    if (inputField.satisfactionsurveyquestionstypeid == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: `O campo Tipo da pergunta precisa ser preenchido na Pergunta da pesquisa de satisfação ${index + 1}`

      });
      return false;
    }

    if (inputField.indcsatisfiedserviceindex && inputField.satisfactionsurveyquestionstypeid == 1) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: `Uma pergunta do tipo "Sim / Não", não pode ser Indicador de medição de indice de satisfação `
      });
      return false;
    }

    this.state.satisfactionsurvey.satisfactionsurveyquestions.forEach((item, index) => {
      if (item.indcsatisfiedserviceindex == true) {
        iterator++;
      }

    })

    if (iterator > 1) {

      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: `Não pode haver mais de uma Pergunta da pesquisa de satisfação com a opção "Indicador de medição de indice de satisfação" ativado`

      });
      this.openOrCloseDialogAddIndcsatisfiedServiceIndexModal(index, inputField)
      return false;

    }


    return inputFieldValidation;

  }

  inputFieldValidation() {

    let inputFieldValidation = true
    let iterator = 0;

    if (this.state.satisfactionsurvey.name == '' || this.state.satisfactionsurvey.name == null || this.state.satisfactionsurvey.name == undefined) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "O campo nome precisa ser preenchido"

      });
      inputFieldValidation = false;
    }
    /*if (this.state.satisfactionsurvey.satisfactionsurveyquestions.length == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: `É necessario ao menos uma pergunta de Pesquisa de satisfação `

      });
      inputFieldValidation = false;
    }
    this.state.satisfactionsurvey.satisfactionsurveyquestions.forEach((item, index) => {

      if (item.name == '' || item.name == null || item.name == undefined) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: `O campo Nome da pergunta precisa ser preenchido na Pergunta da pesquisa de satisfação ${index + 1}`

        });
        inputFieldValidation = false;
      }

      if (item.satisfactionsurveyquestionstypeid == 0) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: `O campo Tipo da pergunta precisa ser preenchido na Pergunta da pesquisa de satisfação ${index + 1}`

        });
        inputFieldValidation = false;
      }
      if (item.indcsatisfiedserviceindex == true) {
        iterator++;
      }

    });

    if (iterator > 1) {
      inputFieldValidation = false
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: `Não pode haver mais de uma Pergunta da pesquisa de satisfação com a opção "Indicador de medição de indice de satisfação" ativado`

      });
    }*/

    return inputFieldValidation;

  }
  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true });
    let data = new FormData();
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    let inputFieldValidation = true;
    inputFieldValidation = this.inputFieldValidation();


    if (inputFieldValidation) {

      /*for (let key in this.state.item) {

        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else if (key.toString().toLowerCase().indexOf('satisfactionsurveyquestions') > -1) {
          this.state.satisfactionsurvey.satisfactionsurveyquestions.forEach((item, index) => {
            data.append(`QuestionsSelected[${index}].id`, item.id ? item.id : 0);
            data.append(`QuestionsSelected[${index}].name`, item.name);
            data.append(`QuestionsSelected[${index}].indcSatisfiedServiceIndex`, item.indcSatisfiedServiceIndex);
            data.append(`QuestionsSelected[${index}].satisfactionsurveyquestionstypeid`, item.satisfactionsurveyquestionstypeid);
          });
        }
        else {
          data.append(key, this.state.item[key]);
        }
      }*/

      for (let key in this.state.satisfactionsurvey) {

        data.append(key, this.state.satisfactionsurvey[key]);
      }

      Api.put('/satisfactionsurvey', data, config)

        .then(result => {
          if (result.data.success) {
            const formattedList = result.data.data;
            const intl = this.props.intl;

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "edit.success" }),
              //files: []
            });
            //this.props.addCurrent(formattedList);
            //this.componentDidMount();

          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });

          }
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
          }
        });

    }



  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {

    this.setState({ loading: true });

    this.setState(prevState => ({
      satisfactionsurvey: {
        ...prevState.satisfactionsurvey,
        [stateName]: value
      }
    }));

    this.setState({ loading: false });

  };

  changeValuesSurveyQuestions(event, index, stateName) {

    PapiroConsole.log("index")
    PapiroConsole.log(index)
    PapiroConsole.log("event")
    PapiroConsole.log(event)
    PapiroConsole.log("stateName")
    PapiroConsole.log(stateName)
    if (event.target.name == "indcsatisfiedserviceindex") {

      const values = [...this.state.satisfactionsurvey.satisfactionsurveyquestions];
      values[index][event.target.name] = event.target.checked;
      this.setState(prevState => ({
        satisfactionsurvey: {
          ...prevState.satisfactionsurvey,
          satisfactionsurveyquestions: values
        }
      }));
    }
    else {
      const values = [...this.state.satisfactionsurvey.satisfactionsurveyquestions];
      values[index][event.target.name] = event.target.value;
      this.setState(prevState => ({
        satisfactionsurvey: {
          ...prevState.satisfactionsurvey,
          satisfactionsurveyquestions: values
        }
      }));

    }
    if (event.target.name == "calledresolvedindicator") {

      const values = [...this.state.satisfactionsurvey.satisfactionsurveyquestions];
      values[index][event.target.name] = event.target.checked;
      this.setState(prevState => ({
        satisfactionsurvey: {
          ...prevState.satisfactionsurvey,
          satisfactionsurveyquestions: values
        }
      }));
    }
    else {
      const values = [...this.state.satisfactionsurvey.satisfactionsurveyquestions];
      values[index][event.target.name] = event.target.value;
      this.setState(prevState => ({
        satisfactionsurvey: {
          ...prevState.satisfactionsurvey,
          satisfactionsurveyquestions: values
        }
      }));

    }
  };

  handleAddFields = () => {
    this.setState(prevState => ({
      satisfactionsurvey: {
        ...prevState.satisfactionsurvey,
        satisfactionsurveyquestions: [
          ...this.state.satisfactionsurvey.satisfactionsurveyquestions,
          { satisfactionsurveyquestionstypeid: 0, name: "", indcsatisfiedserviceindex: false },
        ],
      }
    }), () => {
      // Esta função será chamada após o estado ter sido atualizado
      this.scrollToBottom();
    });

  };
  scrollToBottom = () => {

    //this.ref.current.scrollIntoView({ behavior: 'smooth' });
    //window.scrollTo(0, document.documentElement.scrollHeight);
    const element = document.documentElement; // ou qualquer outro elemento que você deseja rolar para
    element.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  addQuestionIndcsatisfiedServiceIndex = () => {

    this.setState({ loading: true });
    let data = new FormData();
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    if (this.state.setInputFieldToAddIndcsatisfiedServiceQuestion && this.state.setIndexToAddIndcsatisfiedServiceQuestion) {
      PapiroConsole.log("setInputFieldToAddIndcsatisfiedServiceQuestion")
      PapiroConsole.log(this.state.setInputFieldToAddIndcsatisfiedServiceQuestion)
      PapiroConsole.log(this.state.setIndexToAddIndcsatisfiedServiceQuestion)
      data.append("satisfactionsurveyid", this.state.satisfactionsurvey.id);
      data.append("name", this.state.setInputFieldToAddIndcsatisfiedServiceQuestion.name);
      data.append("satisfactionsurveyquestionstypeid", this.state.setInputFieldToAddIndcsatisfiedServiceQuestion.satisfactionsurveyquestionstypeid);
      data.append("indcsatisfiedserviceindex", this.state.setInputFieldToAddIndcsatisfiedServiceQuestion.indcsatisfiedserviceindex);

      Api.post('/satisfactionsurveyquestions', data, config)

        .then(result => {
          if (result.data.success) {
            const formattedList = result.data.data;
            const intl = this.props.intl;
            PapiroConsole.log("result.data")
            PapiroConsole.log(result.data)

            if (result.data.data && this.state.satisfactionsurvey.satisfactionsurveyquestions.length > 0) {
              const values = [...this.state.satisfactionsurvey.satisfactionsurveyquestions];
              values[this.state.setIndexToAddIndcsatisfiedServiceQuestion]['id'] = result.data.data.id;
              this.setState(prevState => ({
                satisfactionsurvey: {
                  ...prevState.satisfactionsurvey,
                  satisfactionsurveyquestions: values
                }
              }));

            }

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "edit.success" }),
              //files: []
            });
            this.openOrCloseDialogAddIndcsatisfiedServiceIndexModal()
            //this.props.addCurrent(formattedList);
            //this.componentDidMount();

          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });

          }
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
          }
        });
    }
    else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: `Ocorreu um erro ao Adicionar a pergunta, por favor tente novamente`

      });
    }



  }

  addQuestion = (index, inputField) => {


    this.setState({ loading: true });
    let data = new FormData();
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    let inputQuestionsValidation = true;

    inputQuestionsValidation = this.inputQuestionsValidation(inputField, index);


    if (inputQuestionsValidation) {


      data.append("satisfactionsurveyid", this.state.satisfactionsurvey.id);
      data.append("name", inputField.name);
      data.append("satisfactionsurveyquestionstypeid", inputField.satisfactionsurveyquestionstypeid);
      data.append("indcsatisfiedserviceindex", inputField.indcsatisfiedserviceindex);
      data.append("calledresolvedindicator", inputField.calledresolvedindicator != null && inputField.calledresolvedindicator != undefined  ? inputField.calledresolvedindicator : false);

      Api.post('/satisfactionsurveyquestions', data, config)

        .then(result => {
          if (result.data.success) {
            const formattedList = result.data.data;
            const intl = this.props.intl;
            PapiroConsole.log("result.data")
            PapiroConsole.log(result.data)

            if (result.data.data && this.state.satisfactionsurvey.satisfactionsurveyquestions.length > 0) {
              const values = [...this.state.satisfactionsurvey.satisfactionsurveyquestions];
              values[index]['id'] = result.data.data.id;
              this.setState(prevState => ({
                satisfactionsurvey: {
                  ...prevState.satisfactionsurvey,
                  satisfactionsurveyquestions: values
                }
              }));

            }

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "edit.success" }),
              //files: []
            });
            //this.props.addCurrent(formattedList);
            //this.componentDidMount();

          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });

          }
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
          }
        });

    }

  }

  deleteQuestion = () => {

    this.setState({ loading: true });

    Api.delete(`/satisfactionsurveyquestions/${this.state.setInputFieldToRemove}`)
      .then(result => {
        if (result.data.success) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
          });

          this.handleRemoveFields(this.state.setIndexToRemove)
          this.openOrCloseRemoveModal()
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);
        }

      })
      .catch(err => {

        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(err);
      });
  }


  handleRemoveFields = (index) => {


    const values = [...this.state.satisfactionsurvey.satisfactionsurveyquestions];
    values.splice(index, 1);
    this.setState(prevState => ({
      satisfactionsurvey: {
        ...prevState.satisfactionsurvey,
        satisfactionsurveyquestions: values
      },
      setIndexToRemove: null,
      setInputFieldToRemove: null,
    }));
  };



  async getSatisfactionSurveyQuestionsType() {
    this.setState({ loading: true });
    var result = await Service.getAll();
    if (result.success) {
      this.setState({ loading: false, satisfactionSurveyQuestionTypeList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  render() {
    const { classes, headerMenu, actionsHeaderMenu, satisfactionsurveys } = this.props;
    const { satisfactionsurvey } = this.state;
    PapiroConsole.log(satisfactionsurvey)
    PapiroConsole.log("satisfactionsurvey")
    PapiroConsole.log("this.state.satisfactionsurveyquestions")
    PapiroConsole.log(this.state.satisfactionsurvey.satisfactionsurveyquestions)
    const intl = this.props.intl;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="edit.item" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField label={intl.formatMessage({ id: "name" })} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={satisfactionsurvey.name} required fullWidth />
                  </Grid>

                  <Grid item xs={11} mt={3}>
                    <Typography variant="h4" component="div">
                      Selecione as perguntas que deseja para sua pesquisa de satisfação
                    </Typography>
                  </Grid>
                  <Grid item xs={1} mt={2}   >
                    {/*<IconButton  style={{borderRadius:'50%', width:'40px', height:'40px'}}   >
                        <AddIcon />
        </IconButton>*/}
                    <Avatar sx={{ bgcolor: "#3f51b4" }} onClick={() => this.handleAddFields()}>
                      <IconButton >
                        <AddIcon style={{ color: 'white' }} />
                      </IconButton>
                    </Avatar>
                  </Grid>
                  <Divider />




                  {satisfactionsurvey.satisfactionsurveyquestions.map((inputField, index) => (

                    <Grid item xs={12} sm={12} mt={1}>
                      <Card >
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={11} >
                              <Typography variant="h5" component="div">
                                Pergunta da pesquisa de satisfação
                              </Typography>
                            </Grid>
                            <Grid item xs={1} style={{ textAlign: "right" }} >
                              <Avatar sx={{ bgcolor: "#b71c1c" }} onClick={() => this.openOrCloseRemoveModal(index, inputField)} >
                                <IconButton >
                                  <RemoveIcon style={{ color: 'white' }} />
                                </IconButton>
                              </Avatar>
                            </Grid>




                            <Grid item xs={12} sm={12} >
                              <SimpleSelect disabled={inputField.id} label={intl.formatMessage({ id: "satisfaction.survey.question.type" })} options={this.state.satisfactionSurveyQuestionTypeList} stateName='satisfactionsurveyquestionstypeid' name='satisfactionsurveyquestionstypeid' changeSelect={(event) => this.changeValuesSurveyQuestions(event, index)} selected={inputField.satisfactionsurveyquestionstypeid} />
                            </Grid>

                            <Grid item xs={12} sm={12} marginTop={2} >
                              <TextField disabled={inputField.id} label={intl.formatMessage({ id: "question.name" })} autoComplete='fname' variant='outlined' name='name' onChange={(event) => this.changeValuesSurveyQuestions(event, index)} value={inputField.name} required fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={12} marginTop={2} style={{ alignSelf: 'flex-end' }}>
                              <SimpleCheckBoxDynamic
                                disabled={inputField.id}
                                label={this.props.intl.formatMessage({ id: "indc.satisfied.service" })}
                                name="indcsatisfiedserviceindex"
                                stateName="indcsatisfiedserviceindex"
                                changeSelect={(event) => this.changeValuesSurveyQuestions(event, index)}
                                selected={inputField.indcsatisfiedserviceindex}
                              />
                            </Grid>
                            {inputField.satisfactionsurveyquestionstypeid == 1 &&
                              <Grid item xs={12} sm={12} style={{ alignSelf: 'flex-end' }}>
                                <SimpleCheckBoxDynamic
                                  disabled={inputField.id}
                                  label={this.props.intl.formatMessage({ id: "indc.called.resolved" })}
                                  name="calledresolvedindicator"
                                  stateName="calledresolvedindicator"
                                  changeSelect={(event) => this.changeValuesSurveyQuestions(event, index)}
                                  selected={inputField.calledresolvedindicator != null && inputField.calledresolvedindicator != undefined  ? inputField.calledresolvedindicator : false  }
                                />
                              </Grid>}
                            {!inputField.id &&
                              <Grid item xs={6} sm={6} marginTop={2} style={{ textAlign: 'right', alignSelf: 'flex-end' }}    >
                                <Button
                                  style={{ margin: '6px' }}
                                  variant='contained'
                                  color='primary'
                                  className={classes.submit}
                                  onClick={() => this.addQuestion(index, inputField)}
                                >
                                  {<FormattedMessage id="save.question" />}
                                </Button>
                              </Grid>
                            }


                          </Grid>



                        </CardContent>

                      </Card>
                    </Grid>



                  ))}

                  <Grid item xs={12} sm={12} marginTop={2}>
                    <SimpleCheckBox
                      label={this.props.intl.formatMessage({ id: "active" })}
                      name="active"
                      stateName="active"
                      changeSelect={this.changeValues}
                      selected={satisfactionsurvey.active}
                    />
                  </Grid>


                  <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4}    >
                        <Button
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            <DialogGeneral
              dialogTitle={this.props.intl.formatMessage({ id: "remove.question" })}
              dialogSubTitle={this.props.intl.formatMessage({ id: "remove.question.info" })}
              open={this.state.DialogRemoveState}
              openOrCloseModalFunction={this.openOrCloseRemoveModal}
              handleConfirmation={this.deleteQuestion}
            />

            <DialogGeneral
              dialogTitle={this.props.intl.formatMessage({ id: "add.indcsatisfiedserviceindex.question" })}
              dialogSubTitle={this.props.intl.formatMessage({ id: "add.indcsatisfiedserviceindex.question.info" })}
              open={this.state.DialogAddIndcsatisfiedServiceIndex}
              openOrCloseModalFunction={this.openOrCloseDialogAddIndcsatisfiedServiceIndexModal}
              handleConfirmation={this.addQuestionIndcsatisfiedServiceIndex}
            />

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
SatisfactionSurveyEdit.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  satisfactionsurveys: state.satisfactionsurveys
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(SatisfactionSurveyEdit)));
