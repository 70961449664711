import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import { FormattedMessage, injectIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Pagination from '@mui/material/Pagination';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import SimpleSelect from '../../components/select/simple-search';
import ClearIcon from '@mui/icons-material/Clear';
import Chip from '@mui/material/Chip';
import * as componentActions from '../../redux/actions/knowledgebases';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { PapiroConsole } from '../../utils/papiroConsole';
import ApiReports from "../../services/apireport";
import fileDownload from 'js-file-download'
//import ReactHtmlParser from 'react-html-parser';

class KnowledgeBases extends Component {

    constructor(props) {
        super(props);

        const { userSession } = this.props;
        const intl = this.props.intl;

        let filters = [
            { name: intl.formatMessage({ id: "keywords" }), id: "keywords" },
            { name: intl.formatMessage({ id: "author" }), id: "createduser" },
            { name: intl.formatMessage({ id: "updated.user" }), id: 'updateduser' },
            { name: intl.formatMessage({ id: "menu.organizationpatrimonies" }), id: "patrimony" },
            { name: intl.formatMessage({ id: "description" }), id: "description" },
            { name: intl.formatMessage({ id: "title" }), id: "title" },
            { name: "Apenas para uso interno?", id: "onlyinternuse" }
        ];

        this.state = {
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],
            contentFilter: '',
            totalPages: 0,
            page: 1,
            pageStart: 0,
            pageEnd: 0,
            count: 0,
            searchValue: '',
            filterSelected: 0,
            filters: filters
        };

        this.isClient = true;

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
        });

        this.closeNotification = this.closeNotification.bind(this);
        this.deleteItem = this.deleteItem.bind(this);

        this.callSearch = this.callSearch.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.changeValues = this.changeValues.bind(this);
        this.addFilter = this.addFilter.bind(this);

        localStorage.setItem('newFilter', '');
        localStorage.setItem('newSearch', '');
    }

    async changeValues(stateName, value, text = '') {

        this.setState({ filterSelected: value });
    }

    onChangePaginate = (e, page) => {
        this.setState({ page: page });
        this.getItens(page);
    }

    setPagination(count, page) {

        let totalPage = Math.ceil(count / 10);
        this.setState({ totalPages: totalPage })
        if (count > 0) {
            if (page == 1) {
                if (count < 10) {
                    this.setState({ pageStart: 1, pageEnd: count });
                } else {
                    this.setState({ pageStart: 1, pageEnd: 10 });
                }
            } else {
                let pageStart = ((page - 1) * 10) + 1;
                if (count >= page * 10) {
                    this.setState({ pageStart: pageStart, pageEnd: page * 10 });
                } else {
                    this.setState({ pageStart: pageStart, pageEnd: count });
                }
            }
        } else {
            this.setState({ totalPages: 0, page: 1, count: 0 });
        }

    }

    async addFilter() {
        const intl = this.props.intl;
        if (this.state.searchValue.trim() == '' && this.state.filterSelected == 0) {
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "caled.list.notification.select.one.filter.option" })
            });
        } else {
            let newFilter = localStorage.getItem('newFilter');
            let newSearch = localStorage.getItem('newSearch');

            if (this.state.searchValue != '' && this.state.filterSelected != 0) {

                if (this.state.filterSelected === "open") {
                    let date = this.state.searchValue.split("/");
                    if (date.length === 3) {
                        date = `${date[2]}-${date[1]}-${date[0]}`
                    }

                    newFilter += this.state.filterSelected + "@";
                    newSearch += date + "@";

                    let nameField;
                    for (let i = 0; i < this.state.filters.length; i++) {

                        if (this.state.filters[i].id === this.state.filterSelected) {
                            nameField = this.state.filters[i].name;
                        }
                    }

                    this.setState({
                        contentFilter: this.state.contentFilter + nameField + ": " + this.state.searchValue + "\n"
                    });

                } else {

                    newFilter += this.state.filterSelected + "@";
                    newSearch += this.state.searchValue + "@";

                    let nameField;
                    for (let i = 0; i < this.state.filters.length; i++) {
                        if (this.state.filters[i].id === this.state.filterSelected) {
                            nameField = this.state.filters[i].name;
                        }
                    }

                    this.setState({
                        contentFilter: this.state.contentFilter + nameField + ": " + this.state.searchValue + "\n"
                    });
                }

                localStorage.setItem("newFilter", newFilter);
                localStorage.setItem("newSearch", newSearch);

                this.setState({
                    searchValue: '',
                    filterSelected: 0
                })
            }
        }

        // console.log(this.state.contentFilter);

    }

    onFieldChange(event) {
        this.setState({ [event.target.name]: event.target.value, page: 1 });
    }

    callSearch = async () => {
        if (this.state.searchValue.trim() != '' && this.state.filterSelected != 0) {
            await this.addFilter();
        }
        if (this.state.contentFilter && this.state.contentFilter.length > 0) {
            this.setState({ loading: true });
            await this.getItens(this.state.page);
           // this.setState({ loading: false });
        }
        /*if (this.state.filterSelected != 0) {
            this.getItens(this.state.page);
        }*/
    }

    async clearFields() {
        await this.setState({ filterSelected: 0, searchValue: '', page: 1, contentFilter: '' })
        localStorage.setItem('newFilter', '');
        localStorage.setItem('newSearch', '');
        this.getItens(1);
    }



    deleteItem(data, resolve) {
        const intl = this.props.intl;
        let abl = this.context.can('Delete', 'KnowledgeBase');
        if (abl == false) {
            this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: this.props.intl.formatMessage({ id: "operation.notAllowed" }) });
            resolve(); return false;
        }

        Api.delete(`/knowledgebases/${data.id}`)

            .then(result => {
                if (result.data.success) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({ id: "called.list.notification.delete.success" }),
                        resultList: []
                    });

                    this.getItens();
                }
                else {
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
                resolve();
            })
            .catch(err => {
                resolve();
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });


    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.location && nextProps.location.state === 'reload') {
            this.componentDidMount();
        }
    }
    componentDidMount() {
        this.getItens();
    }

    getItens(page = 1) {

        let url = `/knowledgebases/paginate?page=${page}`;

        let newFilter = localStorage.getItem('newFilter');
        let newSearch = localStorage.getItem('newSearch');

        /*if (this.state.searchValue != '' && this.state.filterSelected != 0) {
            url += `&search=${this.state.searchValue}&field=${this.state.filterSelected}`
        }*/

        if (newFilter != '' && newSearch != '') {
            url += `&search=${newSearch}&field=${newFilter}`;
            this.setState({ dataFilter: true });
        }

        this.setState({ loading: true });
        Api.get(url)
            .then(result => {
                PapiroConsole.log(result)
                PapiroConsole.log("result")
                if (result.data.success) {

                    let count = result.data.count;
                    this.setState({ count: count });
                    this.setPagination(count, page);

                    const formattedList = result.data.data.map(item => {
                        var keywords = "";
                        item.knowledgebaseknowledgebasekeywords.map(item2 => {
                            keywords = item2.knowledgebasekeyword.word + ", " + keywords;
                        })
                        keywords = keywords.slice(0, -2);

                        var patriomony = "";
                        item.knowledgebasepatrimonies.map(item2 => {
                            patriomony = item2.patrimony.name + ", " + patriomony;
                        })
                        patriomony = patriomony.slice(0, -2);
                        const createdAt = item.createdat ? new Date(item.createdat) : null
                        const updatedAt = item.updatedat ? new Date(item.updatedat) : null
                        return {
                            ...item,
                            CalledId: item.called ? item.called.id : 0,
                            CalledText: item.called ? item.called.name : '',
                            User: item.user ? item.user.name : '',
                            UpdatedUser: item.updateduser ? item.updateduser.name : '',
                            KeyWords: keywords,
                            Patrimony: patriomony,
                            CreatedAt: createdAt ? createdAt.toLocaleString() : "",
                            UpdatedAt: updatedAt ? updatedAt.toLocaleString() : "",
                            //Description: ReactHtmlParser(item.description)
                        }
                    });
                    this.setState({ loading: false, resultList: formattedList });
                }
                else {
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);

            });
    };


    closeNotification() {
        this.setState({ openNotification: false });
    }

    handleDelete(chipToDelete) {
        let valueAtual = this.state.contentFilter.split("\n")
        var newvalue = "";
        valueAtual.map(item => {
            if (item != "" && item != valueAtual[chipToDelete]) {
                newvalue = newvalue + item + " \n"
            }
        })

        this.setState({
            contentFilter: newvalue
        });

        if (newvalue == "") {
            this.clearFields()
        }

    };

    getExcel = () => {
        this.setState({ loading: true });

        const intl = this.props.intl;
        let newFilter = localStorage.getItem('newFilter');
        let newSearch = localStorage.getItem('newSearch');
        newFilter = newFilter.split('@')
        newSearch = newSearch.split('@')
        var url = '/report/knowledgebase/getexcel';

        let title = ""
        let keywords = ""
        let createduser = ""
        let updateduser = ""
        let patrimony = ""
        let description = ""
        let onlyinternuse = ""
        newFilter.forEach((filter, i) => {
            switch (filter) {
                case "title":
                    title += title == "" ? newSearch[i] : "," + newSearch[i]
                    break;
                case "keywords":
                    keywords += keywords == "" ? newSearch[i] : "," + newSearch[i]
                    break;
                case "createduser":
                    createduser += createduser == "" ? newSearch[i] : "," + newSearch[i]
                    break;
                case "updateduser":
                    updateduser += updateduser == "" ? newSearch[i] : "," + newSearch[i]
                    break;
                case "patrimony":
                    patrimony += patrimony == "" ? newSearch[i] : "," + newSearch[i]
                    break;
                case "description":
                    description += description == "" ? newSearch[i] : "," + newSearch[i]
                    break;
                case "description":
                    onlyinternuse += onlyinternuse == "" ? newSearch[i] : "," + newSearch[i]
                default:
                    break;
            }
        });

        url += `?title=${title}&keywords=${keywords}&createduser=${createduser}&updateduser=${updateduser}&patrimony=${patrimony}&description=${description}&onlyinternuse=${onlyinternuse}`;

        ApiReports.get(url, {
            responseType: 'blob'
        })
            .then((result) => {
                fileDownload(result.data, `export_knowledgebase.zip`);

                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: error.data && error.data.response && error.data.response.data && error.data.response.data.errors && error.data.response.data.errors[0] ? error.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
                })
            });

    }

    render() {
        const { classes, headerMenu, actionsHeaderMenu } = this.props;
        const intl = this.props.intl;

        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }
        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft: 0, marginLeft: 0 }}>
                        <CssBaseline />
                        <Grid container className={classes.mb20} spacing={2}>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    disabled={!this.context.can('Add', 'KnowledgeBase')}
                                    size='large'
                                    className={classes.button}
                                    startIcon={<AddIcon />}
                                    component={Link} {...{ to: `/${this.props.match.params.lang}/knowledgebase-registration` }}
                                >
                                    {intl.formatMessage({ id: "add" })}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    disabled={!this.context.can('Add', 'KnowledgeBase')}
                                    size='large'
                                    className={classes.button}
                                    onClick={this.getExcel}
                                >
                                    {intl.formatMessage({ id: "export.plan" })}
                                </Button>
                            </Grid>
                        </Grid>
                        {/* {console.log(this.state.contentFilter)} */}
                        {this.state.contentFilter &&
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={12}>
                                    <Card className="background-title-search">
                                        <CardContent>
                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item xs={8} md={6}>
                                                    <Typography variant="h5">
                                                        {intl.formatMessage({ id: "filters" })}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <strong>
                                                        {this.state.contentFilter.split("\n").map((i, key) => {
                                                            if (i.trim() != "") {
                                                                return <Chip
                                                                    key={key}
                                                                    label={i}
                                                                    onDelete={() => this.handleDelete(key)}
                                                                    color="primary"
                                                                    variant="outlined"
                                                                />
                                                            }

                                                        })}
                                                    </strong>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        }

                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={12}>

                                <Card className="background-title-search">
                                    <CardContent>

                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                                <Typography variant="h5">
                                                    {intl.formatMessage({ id: "knowledgebase" })}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} lg={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <SimpleSelect
                                                    label={intl.formatMessage({ id: "field" })}
                                                    options={this.state.filters}
                                                    stateName='filter'
                                                    changeSelect={this.changeValues}
                                                    selected={this.state.filterSelected} />
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} lg={3}>
                                                <TextField type="text"
                                                    name="searchValue"
                                                    value={this.state.searchValue}
                                                    placeholder={intl.formatMessage({ id: "search" })}
                                                    onChange={this.onFieldChange} />
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={2} lg={1}>
                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    size='large'
                                                    onClick={this.addFilter}
                                                    className={classes.button}
                                                    style={{ marginRight: 4, marginLeft: 8 }} >
                                                    <ZoomInIcon />
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={2} lg={1}>
                                                <Button
                                                    variant='contained'
                                                    color='secondary'
                                                    size='large'
                                                    className={classes.button}
                                                    style={{ marginRight: 4 }}
                                                    onClick={this.callSearch} >
                                                    <Search />
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={2} lg={1}>
                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    size='large'
                                                    onClick={this.clearFields}
                                                    className={classes.button} >
                                                    <ClearIcon />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>

                            </Grid>

                        </Grid>
                        <Can I='List' a='KnowledgeBase'>
                            <MyMaterialTable
                                title={intl.formatMessage({ id: 'knowledgebases' })}
                                rowClick={(event, rowData) => {

                                    /*let abl = this.context.can('Edit', 'KnowledgeBase');
                                    if (abl == false)
                                    {
                                        this.setState({ loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: this.props.intl.formatMessage({id:"operation.notAllowed"})});
                                        return false;
                                    }*/
                                    this.props.addCurrent(rowData);
                                    this.props.history.push(`/${this.props.match.params.lang}/knowledgebase-edit`);


                                }}
                                columns={[
                                    { title: intl.formatMessage({ id: "id" }), field: 'id' },
                                    { title: intl.formatMessage({ id: "title" }), field: 'title' },
                                    { title: intl.formatMessage({ id: "author" }), field: 'User' },
                                    { title: intl.formatMessage({ id: "created.at" }), field: 'CreatedAt' },
                                    { title: intl.formatMessage({ id: "updated.user" }), field: 'UpdatedUser' },
                                    { title: intl.formatMessage({ id: "updated.at" }), field: 'UpdatedAt' },
                                    { title: intl.formatMessage({ id: "keywords" }), field: 'KeyWords' },
                                    { title: intl.formatMessage({ id: "patrimony" }), field: 'Patrimony' },
                                ]}
                                data={this.state.resultList}
                                deleteItem={this.deleteItem}
                            />
                        </Can>

                        <Can I='List' a='KnowledgeBase'>

                            <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                                <Grid item xs={12} md={3}>
                                    <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePaginate} />
                                </Grid>
                                <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                                    {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                                </Grid>
                            </Grid>

                        </Can>

                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}


                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
            </div>
        );
    }
}
KnowledgeBases.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(KnowledgeBases)));
KnowledgeBases.contextType = AbilityContext;
