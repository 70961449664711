import Api2Talk from "../api2talk";

const PATH = '/channelwebchat';

const create = async (input) => { 
  try {
    const response = await Api2Talk.post(PATH, input);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getChannelsByWebchatId = async (id) => { 
  try {
    const response = await Api2Talk.get(`${PATH}/by-webchat/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const deleteByChannelWebchatById = async (id) => {
  try {
    const response = await Api2Talk.delete(`${PATH}/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
}

const setDefault = async (id) => {
  try {
    const response = await Api2Talk.put(`${PATH}/setdefault/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export {
  create,
  getChannelsByWebchatId,
  deleteByChannelWebchatById,
  setDefault
}