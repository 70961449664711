const extractPrimitives = (obj, integrationService) => {
  const result = [];

  function recurse(currentObj) {
    let index = 1;

    for (let key in currentObj) {
      if (currentObj.hasOwnProperty(key)) {
        const value = currentObj[key];

        if ((typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') && (key !== integrationService?.namefielddisplay && key !== integrationService?.namefieldvalue)) {
          result.push({
            id: index,
            fieldname: key,
            isEditable: true
          });
          index++;
        } else if (Array.isArray(value) || (value && typeof value === 'object')) {
          continue;
        }
      }
    }
  }

  if (obj) {
    recurse(obj);
  }
  
  return result;
}

export default extractPrimitives;