import { Component } from "react";
import { bindActionCreators } from "redux";
import PropTypes from 'prop-types';
import * as componentActions from '../../redux/actions/customers';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { AbilityContext } from '../../config/ability-context';
import HeaderMenu from "../../components/header-menu";
import Api from "../../services/api";
import clsx from 'clsx';
import { Button, CircularProgress, Container, CssBaseline, Grid, Avatar, TextField, Typography } from "@mui/material";
import { browserHistory } from '../../helpers/history';
import Api2Talk from "../../services/api2talk";
import { Overlay } from "../../styles/global.js";
import CustomizedSnackbars from "../../components/material-snackbars/index.js"; 
import PeopleIcon from '@mui/icons-material/People';
import SimpleAutoComplete from "../../components/auto-complete/autocomplete.js";
import PhoneInput from "react-phone-input-2";


class CustomerEdit extends Component {

  constructor(props) {
    super(props);

    const { userSession, customers } = this.props;
    this.globalAdm = false;
    this.isClient = true;

    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    if (!customers || (customers && !customers.customer)) {
      var lang = this.props.match.params.lang;
      document.location.href = `/${lang}/customers`
    }

    this.formDataInitialState = {
      Id: customers.customer.id,
      UpdatedUser: userSession.user.id,
      Name: customers.customer.name,
      Email: '',
      Phone: '',
      CustomerOrigin: null
    }; 

    this.state = {
      formData: this.formDataInitialState,
      optionsCustomerOrigin: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
    };

    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
  }

  fetchDataNotSuccess(result) {
    const intl = this.props.intl;
    this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    });
  }

  fetchDataThrowError(err) {
    const intl = this.props.intl;
    this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    });
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value
      }
    }));
  };

  handleChangePhone(e) {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        'Phone': e
      }
    }));
  }

  handleSubmit = async e => {
    e.preventDefault();
    const payload = {
      ...this.state.formData,
      CustomerOrigin: this.state.formData.CustomerOrigin ? this.state.formData.CustomerOrigin?.id : null 
    }
    this.setState({ loading: true });
    Api2Talk.put(`/customer`, payload)
      .then(result => {
          if (result.data.success) {
              this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'success',
                  formData: this.state.formData,
                  techinicalList : [],
                  selectedTechnicians: [],
                  notificationMessage: 'Registro atualizado com sucesso'
              });
          } else {
              this.setState({ loading: false });
              this.fetchDataNotSuccess(result);
              Api.kickoff(result);
          }
      })
      .catch(err => {
          this.setState({ loading: false });
          this.fetchDataThrowError(err);
          Api.kickoff(err);
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  handleChangeAutoComplete(stateName, value) {  
    let data = value.id > 0 ? value : null ;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [stateName]: data
      }
    }));

  }

  fetchOptionsCustomerOrigins = async () => {
    this.setState({ loading: true });
    Api2Talk.get(`/customer/origins`)
      .then(result => {
          this.setState({ loading: false });
          if (result.data.success) {
              const customerOriginsCollection = result.data.data;
              const customerOriginsOptions =  customerOriginsCollection.map((item) => ({ id: item.id, name: item.name }));
              this.setState(prevState => ({
                ...prevState,
                optionsCustomerOrigin: customerOriginsOptions
              }))
          } else {
              this.fetchDataNotSuccess(result);
              Api.kickoff(result);
          }
      })
      .catch(err => {
          this.setState({ loading: false });
          this.fetchDataThrowError(err);
          Api.kickoff(err);
      });
  }

  fetchCustomerData = async () => {
    this.setState({ loading: true });
    const {customers} = this.props;
    Api2Talk.get(`/customer/${customers.customer.id}`)
      .then(result => {
          if (result.data.success) {
              const customer_origin = result.data.data.customerorigin ? this.state.optionsCustomerOrigin.find(item => item.id === result.data.data.customerorigin) : null;
              this.setState({
                  loading: false,
                  formData: {
                    ...this.state.formData,
                    Email: result.data.data.email,
                    Phone: result.data.data.phone,
                    CustomerOrigin: customer_origin
                  }
              });
          } else {
              this.setState({ loading: false });
              this.fetchDataNotSuccess(result);
              Api.kickoff(result);
          }
      })
      .catch(err => {
          this.setState({ loading: false });
          this.fetchDataThrowError(err);
          Api.kickoff(err);
      });

  }

  async componentDidMount() {
    await this.fetchOptionsCustomerOrigins();
    await this.fetchCustomerData();
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
   
    const { formData } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
         <HeaderMenu />
         <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
         >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary' onClick={browserHistory.goBack}><FormattedMessage id="back" /></Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar mt={7} style={{ backgroundColor: '#303f9f', color: 'white' }}>
                <PeopleIcon />
              </Avatar>
              <Typography component='h1' variant='h5' mt={2}>
                {intl.formatMessage({ id: "edit.item" })}
              </Typography>

              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12}>
                      <TextField 
                        inputProps={{ maxLength: 250 }} 
                        fullWidth 
                        label={intl.formatMessage({id:"name"})} 
                        autoComplete='fname' 
                        variant='outlined' 
                        name='Name' 
                        onChange={this.handleInputChange} 
                        value={formData.Name} 
                        required 
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField 
                        type="email"
                        inputProps={{ maxLength: 250 }} 
                        fullWidth 
                        label={intl.formatMessage({id:"email"})} 
                        autoComplete='femail' 
                        variant='outlined' 
                        name='Email' 
                        onChange={this.handleInputChange} 
                        value={formData.Email} 
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <PhoneInput
                        required
                        inputStyle={{
                          padding: '23.5px 14px 23.5px 58px',
                          width: '100%'
                        }}
                        id="phone"
                        name="Phone"
                        country={'br'}
                        InputLabel={intl.formatMessage({ id: "phone" })}
                        specialLabel={intl.formatMessage({ id: "phone" })}
                        inputProps={{
                          name: intl.formatMessage({ id: "phone" })
                        }} 
                        onChange={this.handleChangePhone} 
                          value={formData.Phone} 
                        
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete 
                        name="CustomerOrigin"
                        label={intl.formatMessage({ id: "select.customer_origin" })}
                        options={this.state.optionsCustomerOrigin}
                        stateName='CustomerOrigin'
                        changeSelect={(stateName, value) => {
                          this.handleChangeAutoComplete(stateName, value)
                        }}
                        selected={this.state.formData.CustomerOrigin}
                        htmlOptions={true}
                      />
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                      <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                        <Grid item xs={12} sm={4}    >
                          <Button 
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                          >
                            {<FormattedMessage id="save" />}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            {
              this.state.openNotification && (
                <CustomizedSnackbars
                  variant={this.state.notificationVariant}
                  message={this.state.notificationMessage}
                  isOpen={this.state.openNotification}
                  toClose={this.closeNotification}
                />
              )
            }          
          </Container>
         </main>
      </div>
    )
  }
}

CustomerEdit.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  customers: state.customers
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CustomerEdit)));

CustomerEdit.contextType = AbilityContext;