import { Button, CircularProgress, Grid, Switch, TextField, Typography } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Component } from "react";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import SimpleAutoComplete from "../../../../components/auto-complete/autocomplete.js";
import CustomizedSnackbars from "../../../../components/material-snackbars/index.js";
import { AbilityContext } from '../../../../config/ability-context';
import * as componentActions from '../../../../redux/actions/channels.js';
import Api from "../../../../services/api";
import Api2Talk from "../../../../services/api2talk";
import { Overlay } from "../../../../styles/global.js";
import { Styles } from '../../../../styles/material-styles';

import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SimpleCheckBox from '../../../../components/checkbox/check';
import DialogGeneral from '../../../../components/dialog-general';
import SimpleHelp from '../../../../components/help';
import * as ItemServiceChannelAssociation from '../../../../services/2talk/channeltechinicalteam.js';
import * as ServiceUser from '../../../../services/techinicalteamuser.service';
import { PapiroConsole } from "../../../../utils/papiroConsole.js";

import * as CalledService from '../../../../services/called.service';

const styles = {
  header: {
    marginBottom: 16,
    //  borderBottom: '2px solid #ccc', // Adapte a cor da borda conforme necessário
    paddingBottom: 8,
    fontWeight: 'bold',
    color: '#777'

  },
};

class RegistrationData extends Component {


  constructor(props) {
    super(props);

    const { userSession, channels } = this.props;

    this.globalAdm = false;
    this.isClient = true;

    if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    if (!channels || (channels && !channels.channel)) {
      var lang = this.props.match.params.lang;
      document.location.href = `/${lang}/channels`
    }

    this.channelInitialState = {
      Id: channels.channel.id,
      Name: channels.channel.name,
      DisplayName: channels.channel.displayname,
      CreatedUser: userSession.user.id,
      ServiceRulesId: null,
      TechinicalTeamId: null,
      DistributionStrategyId: null,
      MessageProviderId: null,
      Published: channels.channel.published,
      Active: channels.channel.active,
      selectedTechnicians: [],
      DefaultOrganizationId: null,
      DefaultOrganizationCategoryId: null
    };

    this.state = {
      channel: this.channelInitialState,
      files: [],
      optionsServiceRules: [],
      optionsTechinicalTeams: [],
      optionsMessageProviders: [],
      optionsDistributionsStrategies: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      selectedTechnicians: [],
      autocompleteInputValue: '',
      dialogState: false,
      techinicalList: [],
      newTechinicalTeamValue: null,
      oldTechinicalTeamValue: null,
      DialogRemoveState: false,
      IdToDelete: { id: 0, index: 0 },
      techinicalListTotal: [],
      ViewAllTechs: false,
      techInput : '',
      organizationsList: [],
      categoryList: [],
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeTech = this.handleInputChangeTech.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.handleDeleteOption = this.handleDeleteOption.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.getOrganizations = this.getOrganizations.bind(this);
    this.getCategoriesByOrganization = this.getCategoriesByOrganization.bind(this);
  }

  requestNotSuccess(result) {
    const intl = this.props.intl;
    this.setState({
      loading: false, openNotification: true, notificationVariant: "error",
      notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
    });
  }

  requestThrowError(err) {
    const intl = this.props.intl;
    this.setState({
      loading: false, openNotification: true, notificationVariant: "error",
      notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
    });
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => ({
      channel: {
        ...prevState.channel,
        [name]: value
      }
    }));
  }

  handleInputChangeTech(e) {
    PapiroConsole.log(e)
    PapiroConsole.log("evento aqui")
    if(e && e.target ){
      const name = e.target.name;
      const value = e.target.value;
      this.setState({techInput: value })
    }
    
  }

  notificationMessage(message) {
    this.setState({
      loading: false,
      openNotification: true,
      notificationVariant: 'error',
      notificationMessage: message
    });
  }

  validateFieldsRequired() {
    const intl = this.props.intl;

    if (this.state.channel.Name == null || this.state.channel.Name.length == 0) {
      const message = intl.formatMessage({ id: "channel.missing.name" });
      this.notificationMessage(message);
      return false;
    }

    if (this.state.channel.MessageProviderId == null || this.state.channel.MessageProviderId.id === undefined) {
      const message = intl.formatMessage({ id: "channel.missing.message_provider" });
      this.notificationMessage(message);
      return false;
    }

    if (this.state.channel.TechinicalTeamId == null || this.state.channel.TechinicalTeamId.id === undefined) {
      const message = intl.formatMessage({ id: "channel.missing.techinical_team" });
      this.notificationMessage(message);
      return false;
    }

    return true;
  }

  handleSubmit = async e => {
    e.preventDefault();

    if (!this.validateFieldsRequired()) return;

    let formData = { ...this.state.channel };
    formData.DistributionStrategyId = formData.DistributionStrategyId?.id ?? null;
    formData.MessageProviderId = formData.MessageProviderId?.id ?? null;
    formData.ServiceRulesId = formData.ServiceRulesId?.id ?? null;
    formData.TechinicalTeamId = formData.TechinicalTeamId?.id ?? null;
    formData.selectedTechnicians = this.state.selectedTechnicians;
    formData.DefaultOrganizationId = formData.DefaultOrganizationId?.id ?? null;
    formData.DefaultOrganizationCategoryId = formData.DefaultOrganizationCategoryId?.id ?? null;
    PapiroConsole.log(formData)
    PapiroConsole.log("formData")
    this.props.setLoadingState(true)

    this.props.setMessageProviderIdCurrentChannel(formData.MessageProviderId ?? null);

    Api2Talk.put(`/channel`, formData)
      .then(result => {
        const intl = this.props.intl;
        this.props.setLoadingState(false)
        if (result.data.success) {
          PapiroConsole.log(result.data.data.channeltechinicalteams)
          PapiroConsole.log("result.data.data.channeltechinicalteams")
          if (result.data.data && result.data.data.channeltechinicalteams) {
            this.props.addCurrent(result.data.data)

            let techinicalList = [];
            let array = result.data.data.channeltechinicalteams;
            array.forEach(element => {
              techinicalList.push({ id: element.userid, order: element.queueposition, name: element.user.name, channeltechinicalteamid: element.id })
            });

            let selected_technicians_list = techinicalList.filter(item1 => this.state.techinicalList.some(item2 => item1.id === item2.id));

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "edit.success" }),
              selectedTechnicians: selected_technicians_list
            });
          }


        } else {
          this.requestNotSuccess(result);
          Api.kickoff(result);
        }
      })
      .catch(err => {
        this.props.setLoadingState(false)
        this.requestThrowError(err);
        Api.kickoff(err);
      });
  }

  fetchOptionsServiceRules = async () => {
    this.props.setLoadingState(true);
    try {
      const result = await Api2Talk.get(`/servicerules`);
      if (result.data.success) {
        const serviceRulesCollection = result.data.data;
        const serviceRulesOptions = serviceRulesCollection.map((item) => ({ id: item.id, name: item.name }));
        this.setState({ optionsServiceRules: serviceRulesOptions })
        return serviceRulesOptions;
        //this.setState({ optionsServiceRules: serviceRulesOptions });
      } else {
        this.requestNotSuccess(result);
        Api.kickoff(result);
      }
    } catch (err) {
      this.requestThrowError(err);
      Api.kickoff(err);
    }
  };


  fetchOptionsTechinicalTeam = async () => {
    this.props.setLoadingState(true);
    try {
      const result = await Api2Talk.get(`/techinicalteam`);
      if (result.data.success) {
        const techinicalTeamOptions = result.data.data;
        this.setState({ optionsTechinicalTeams: techinicalTeamOptions });

        return techinicalTeamOptions;

      } else {
        this.requestNotSuccess(result);
        Api.kickoff(result);
      }
    } catch (err) {
      this.requestThrowError(err);
      Api.kickoff(err);
    }
  };

  fetchOptionsMessageProvider = async () => {
    this.props.setLoadingState(true);
    try {
      const result = await Api2Talk.get(`/messageprovider`);
      if (result.data.success) {
        const messageProviderOptions = result.data.data;
        this.setState({ optionsMessageProviders: messageProviderOptions })
        return messageProviderOptions;
        //this.setState({ optionsMessageProviders: messageProviderOptions });
      } else {
        this.requestNotSuccess(result);
        Api.kickoff(result);
      }
    } catch (err) {
      this.requestThrowError(err);
      Api.kickoff(err);
    }
  };

  fetchOptionsDistributionsStrategies = async () => {
    this.props.setLoadingState(true);
    try {
      const result = await Api.get(`/distributionstrategies`);
      if (result.data.success) {
        const distriburionsStrategyCollection = result.data.data;
        const distributionsStrategiesOptions = distriburionsStrategyCollection.map((item) => ({ id: item.id, name: item.name }));
        this.setState({ optionsDistributionsStrategies: distributionsStrategiesOptions })
        return distributionsStrategiesOptions;
        //this.setState({ optionsDistributionsStrategies: distributionsStrategiesOptions });
      } else {
        this.requestNotSuccess(result);
        Api.kickoff(result);
      }
    } catch (err) {
      this.requestThrowError(err);
      Api.kickoff(err);
    }
  };

  async fetchChannelData() {
    this.props.setLoadingState(true)

    const { channels } = this.props;
    PapiroConsole.log(channels)
    PapiroConsole.log("channels")
    const _service_rules = await this.fetchOptionsServiceRules();
    const _techinical_team = await this.fetchOptionsTechinicalTeam();
    const _distribution_strategy = await this.fetchOptionsDistributionsStrategies();
    const _message_provider = await this.fetchOptionsMessageProvider();
    await this.getOrganizations();


    let channelData = {
      ServiceRulesId: channels.channel.servicerulesid,
      TechinicalTeamId: channels.channel.techinicalteamid,
      DistributionStrategyId: channels.channel.distributionstrategyid,
      MessageProviderId: channels.channel.messageproviderid,
      DefaultOrganizationId: channels.channel.defaultorganizationid,
      DefaultOrganizationCategoryId: channels.channel.defaultorganizationcategoryid,
      TechinicalList: []
    }

    this.props.setMessageProviderIdCurrentChannel(channels.channel.messageproviderid ?? null);

    if (channels.channel.channeltechinicalteams && channels.channel.channeltechinicalteams.length > 0) {
      let array = channels.channel.channeltechinicalteams;
      array.forEach(element => {
        channelData.TechinicalList.push({ id: element.userid, order: element.queueposition, name: element.user.name, channeltechinicalteamid: element ? element.id : null })
      });
    }


    const service_rules = _service_rules?.find((serviceRules) => serviceRules.id === channelData.ServiceRulesId);
    const techinical_team = _techinical_team?.find((techinicalTeam) => techinicalTeam.id === channelData.TechinicalTeamId);
    const distribution_strategy = _distribution_strategy?.find((distributionStrategy) => distributionStrategy.id === channelData.DistributionStrategyId);
    const message_provider = _message_provider?.find((messageProvider) => messageProvider.id === channelData.MessageProviderId);
   
    let selected_technicians_list = channelData.TechinicalList;
    selected_technicians_list.sort((a, b) => a.order - b.order)

    let organization, category = null;
    let organizationId = channelData.DefaultOrganizationId;
    let organizationCategoryId = channelData.DefaultOrganizationCategoryId;

    if (organizationId) {
      organization = this.state.organizationsList?.find(organizationItem => organizationItem?.id === organizationId);

      await this.getCategoriesByOrganization(organizationId);

      if (organizationCategoryId) {
        category = this.state.categoryList?.find(categoryItem => categoryItem?.id === organizationCategoryId);
      }
    }

    this.setState(prevState => ({
      channel: {
        ...prevState.channel,
        ServiceRulesId: (service_rules === undefined) ? null : service_rules,
        TechinicalTeamId: (techinical_team === undefined) ? null : techinical_team,
        DistributionStrategyId: (distribution_strategy === undefined) ? null : distribution_strategy,
        MessageProviderId: (message_provider === undefined) ? null : message_provider,
        DefaultOrganizationId: organization,
        DefaultOrganizationCategoryId: category
      },
      selectedTechnicians: selected_technicians_list


    }))

    this.props.setLoadingState(false)

    /* Api2Talk.get(`/channel/${this.state.channel.Id}`)
       .then(async result => {
         this.props.setLoadingState(false)
         if (result.data.success) {
           const channelData = result.data.data;
           const service_rules = _service_rules.find((serviceRules) => serviceRules.id === channelData.servicerulesid);
           const techinical_team = _techinical_team.find((techinicalTeam) => techinicalTeam.id === channelData.techinicalteamid);
           const distribution_strategy = _distribution_strategy.find((distributionStrategy) => distributionStrategy.id === channelData.distributionstrategyid);
           const message_provider = _message_provider.find((messageProvider) => messageProvider.id === channelData.messageproviderid);
           
           const _techinical_list = await this.getAllTechByTeam(techinical_team ? techinical_team.id : null );
           if (channelData.channeltechinicalteams && channelData.channeltechinicalteams.length > 0) {
             let array = channelData.channeltechinicalteams;
             array.forEach(element => {
               channelData.TechinicalList.push({ id: element.userid, order: element.queueposition, name: element.user.name, channeltechinicalteamid: element ? element.id : null })
             });
           }
           PapiroConsole.log(_techinical_list)
           PapiroConsole.log("_techinical_list")
           PapiroConsole.log("channelData.TechinicalList")
           PapiroConsole.log(channelData)
           let selected_technicians_list = channelData.TechinicalList.filter(item1 => _techinical_list.some(item2 => item1.id === item2.id));
           //let selected_technicians_list = channelData.TechinicalList.filter(item1 => _techinical_list.some(item2 => item1.userid === item2.id));
           selected_technicians_list.sort((a, b) => a.order - b.order)
           PapiroConsole.log(selected_technicians_list)
           PapiroConsole.log("selected_technicians_list")
 
 
           this.setState(prevState => ({
             channel: {
               ...prevState.channel,
               ServiceRulesId: (service_rules === undefined) ? null : service_rules,
               TechinicalTeamId: (techinical_team === undefined) ? null : techinical_team,
               DistributionStrategyId: (distribution_strategy === undefined) ? null : distribution_strategy,
               MessageProviderId: (message_provider === undefined) ? null : message_provider,
               DisplayName: channelData.displayname,
               Published: channelData.published,
               Active: channelData.active,
             },
             selectedTechnicians: selected_technicians_list
           }))
 
           
 
         } else {
           this.requestNotSuccess(result);
           Api.kickoff(result);
         }
       })
       .catch(err => {
         this.props.setLoadingState(false)
         this.requestThrowError(err);
         Api.kickoff(err);
       });*/
  }

  handleChangeAutoComplete = async (stateName, value) => {
    let data = value.id > 0 ? value : null;

    this.setState(prevState => ({
      channel: {
        ...prevState.channel,
        [stateName]: data
      }
    }));

    if (stateName == "MessageProviderId" && value.id > 0) {
      this.props.setMessageProviderIdCurrentChannel(value.id ?? null);
    }

    else if(stateName == "DefaultOrganizationId" && data) {
      await this.getCategoriesByOrganization(data.id);
    }
  }

  handleChangeCheckbox(stateName, value) {

    if (stateName == "ViewAllTechs") {

      var techinicalList = []
      PapiroConsole.log(value)
      PapiroConsole.log("value")
      if (value == true) {

        techinicalList = this.state.techinicalListTotal;

      }
      else {
        let result = this.state.techinicalListTotal
        techinicalList = result.filter(p => p.enableuser != null);
      }

      this.setState({ ViewAllTechs: value, techinicalList: techinicalList })

    }
  }

  handleChangeSwitch(event) {
    const stateName = event.target.name;
    const value = event.target.checked;
    this.setState(prevState => ({
      channel: {
        ...prevState.channel,
        [stateName]: value
      }
    }));
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  handleTechnicianSelect = (technician) => {
    const order = this.state.selectedTechnicians.length + 1;
    this.setState((prevState) => ({
      selectedTechnicians: [...prevState.selectedTechnicians, { ...technician, order }],
      techInput : ''
    }));
  };

  filterOptions = (options,  state ) => {
    const { selectedTechnicians } = this.state;

    PapiroConsole.log("options aqui")
    
    PapiroConsole.log(options)
    PapiroConsole.log(state)
    PapiroConsole.log("state")
    return options.filter(
      (option) =>
        !selectedTechnicians.find((selected) => selected.id === option.id) &&
        !this.state.selectedTechnicians.find((selected) => selected.id === option.id) && 
        option.name.toLowerCase().includes(state.inputValue.toLowerCase())
    );
  };

  handleOrderChange = (result) => {
    if (!result.destination) {
      return;
    }

    const updatedTechnicians = [...this.state.selectedTechnicians];
    const [movedTechnician] = updatedTechnicians.splice(result.source.index, 1);
    updatedTechnicians.splice(result.destination.index, 0, movedTechnician);

    // Atualiza a ordem após a reordenação
    updatedTechnicians.forEach((technician, index) => {
      technician.order = index + 1;
    });

    this.setState({ selectedTechnicians: updatedTechnicians });
  };

  handleTechnicianDelete = (technician, index) => {

    PapiroConsole.log()
    if (technician.channeltechinicalteamid) {
      this.setState({ DialogRemoveState: true, IdToDelete: { id: technician.channeltechinicalteamid, index: index } })
    }
    else {
      const updatedTechnicians = [...this.state.selectedTechnicians];
      updatedTechnicians.splice(index, 1);

      // Atualiza a ordem após a exclusão
      updatedTechnicians.forEach((technician, i) => {
        technician.order = i + 1;
      });


      this.setState({ selectedTechnicians: updatedTechnicians });
    }

  };

  async handleDeleteOption() {

    const { getNotification, setLoadingState } = this.props;

    const intl = this.props.intl
    setLoadingState(true)

    if (this.state.IdToDelete && this.state.IdToDelete.id != 0) {
      var result = await ItemServiceChannelAssociation.DeleteOption(this.state.IdToDelete.id)
      if (result.success) {
        const updatedTechnicians = [...this.state.selectedTechnicians];
        updatedTechnicians.splice(this.state.IdToDelete.index, 1);

        // Atualiza a ordem após a exclusão
        updatedTechnicians.forEach((technician, i) => {
          technician.order = i + 1;
        });
        PapiroConsole.log(result.data)
        PapiroConsole.log("result.data")
        this.props.updatedChannelTechinicalTeams(result.data)

        this.setState({ selectedTechnicians: updatedTechnicians });
        setLoadingState(false)
        getNotification(false, true, 'success', "Associação de canal deletado com sucesso")
        this.setState({ DialogRemoveState: false, IdToDelete: { id: 0, index: 0 } });

      } else {
        const intl = this.props.intl;
        getNotification(false, true, 'error', result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      }
    }



  }

  openOrCloseRemoveModal = () => {
    this.setState({ DialogRemoveState: false })
  }

  handleChangeAutoCompleteTechinicalTeam(e, value) {

    //fazer casos quando eu deixar o campo vazios e caso pra que eu altere pra ele mesmo
    if (value) {
      this.setState({ newTechinicalTeamValue: value })
      this.setState({ oldTechinicalTeamValue: this.state.channel.TechinicalTeamId })
      this.openOrCloseDialogState()
    }

    //abrir modal 

  }

  openOrCloseDialogState = () => {
    this.setState({ dialogState: !this.state.dialogState });
  }

  handleTeamChange = () => {

    //Time atualizado 
    this.setState(prevState => ({
      channel: {
        ...prevState.channel,
        "TechinicalTeamId": this.state.newTechinicalTeamValue
      }
    }));

    //lista de técnicos selecionada vazia 
    this.setState({ selectedTechnicians: [] })

    //alterar lista opções de técnicos pro usuário
    this.getAllTechByTeam(this.state.newTechinicalTeamValue.id);

    //fechar modal
    this.openOrCloseDialogState()
  }

  handleCancel = () => {

    this.openOrCloseDialogState()

    //Time atualizado 
    this.setState(prevState => ({
      channel: {
        ...prevState.channel,
        "TechinicalTeamId": this.state.oldTechinicalTeamValue
      }
    }));

  }

  async getAllTechByTeam(techinicalTeamId) {

    PapiroConsole.log(techinicalTeamId)
    PapiroConsole.log("techinicalTeamId")
    if (techinicalTeamId == null) {
      this.setState({ loading: false, techinicalList: [] });
      return [];
    }
    var result = await ServiceUser.getAllTechByTeamAndEnableUser2talk(techinicalTeamId);
    if (result.success) {
      PapiroConsole.log(result)
      var techinicalList = []

      if (this.state.ViewAllTechs) {
        techinicalList = result.data;
      }
      else {

        techinicalList = result.data.filter(p => p.enableuser != null);

      }
      this.setState({ loading: false, techinicalList: techinicalList, techinicalListTotal: result.data });

      // this.setState({ loading: false, techinicalList: result.data });
      return result.data;

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  async getOrganizations(isLoading = false) {
    if(isLoading) {
      this.setState({ loading: true });
    }
      
    const { userSession } = this.props
    let orgData = []

    if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
      orgData = userSession.user.userorganizations
    } else {
      const result = await CalledService.getOrganizations();

      if (result.success) {
        orgData = result.data
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(result);
      }
    }

    if(orgData != null) {

      orgData= orgData.filter(c => c.active == true);
      if (this.globalAdm) {
        this.setState({ organizationsList: orgData && orgData.length > 1 ? orgData.sort((a, b) => a.name.localeCompare(b.name)) : orgData });
        if(isLoading) {
          this.setState({ loading: false });
        }  
      }
      else {
        this.setState({ organizationsList: orgData && orgData.length > 1 ? orgData.sort((a, b) => a.name.localeCompare(b.name)) : orgData });
        
        //await this.updateOrganization(this.organizationId);
        //this.setState({ organization: this.organizationId });

        if(isLoading)
          this.setState({ loading: false });
      }
      if(this.globalAdm || this.AdmOrganization) {
       
        if(orgData && orgData.length == 1) {
          this.setState((prevState) => ({
            called: {
              ...prevState.called,
              organization: orgData[0]
            }
          }), () => {
            this.organizationId = orgData[0].id
            this.updateOrganization(this.organizationId);
            
          })
          
        }
        else{
          this.setState(prevState => ({
            called: {
              ...prevState.called,
              organization: null
            }
          }))
        }
      }
    } else {
      orgData = []
      this.setState({ organizationsList: orgData && orgData.length > 1 ? orgData.sort((a, b) => a.name.localeCompare(b.name)) : orgData });
      if(isLoading)
        this.setState({ loading: false });
    }
  };

  getCategoriesByOrganization = async (organizationId) => {
    const intl = this.props.intl;
    var result = await CalledService.getCategoriesByOrganization(organizationId);

    if (result.success) {

      let categories = result.data && result.data.length > 1 
      ? result.data.sort((a, b) => a.name.localeCompare(b.name)) 
      : result.data;

      categories = categories.map(item => ({id:item?.id, name:item?.name}));

      this.setState({ 
        categoryList: categories
      });
    } else {
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  async componentDidMount() {
    await this.fetchChannelData();
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { channel, selectedTechnicians, autocompleteInputValue } = this.state;
    const intl = this.props.intl;

    PapiroConsole.log("this.state.selectedTechnicians")
    PapiroConsole.log(this.state.selectedTechnicians)
    PapiroConsole.log(this.props)

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div style={{ paddingTop: '10px' }}>
        <div>
          <form name='myForm' className={classes.form} encType='multipart/form-data'>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={12}>
                <TextField
                  inputProps={{ maxLength: 250 }}
                  fullWidth
                  label={intl.formatMessage({ id: "name" })}
                  autoComplete='fname'
                  variant='outlined'
                  name='Name'
                  onChange={this.handleInputChange}
                  value={channel.Name}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  inputProps={{ maxLength: 250 }}
                  fullWidth
                  label={intl.formatMessage({ id: "display_name" })}
                  autoComplete='fdisplay_name'
                  variant='outlined'
                  name='DisplayName'
                  onChange={this.handleInputChange}
                  value={channel.DisplayName}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <SimpleAutoComplete
                  name="MessageProviderId"
                  label={intl.formatMessage({ id: "select.message_provider" })}
                  options={this.state.optionsMessageProviders}
                  stateName='MessageProviderId'
                  changeSelect={(stateName, value) => {
                    this.handleChangeAutoComplete(stateName, value)
                  }}
                  selected={this.state.channel.MessageProviderId}
                  htmlOptions={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                {/*<SimpleAutoComplete
                  name="TechinicalTeamId"
                  label={intl.formatMessage({ id: "select.techinical_team" })}
                  options={this.state.optionsTechinicalTeams}
                  stateName='TechinicalTeamId'
                  changeSelect={(stateName, value) => {
                    this.handleChangeAutoCompleteTechinicalTeam(stateName, value)
                  }}
                  selected={this.state.channel.TechinicalTeamId}
                  htmlOptions={true}
                  required
                />*/}
                <Autocomplete
                  options={this.state.optionsTechinicalTeams}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => value && this.handleChangeAutoCompleteTechinicalTeam(event, value)}
                  value={this.state.channel.TechinicalTeamId}
                  renderInput={(params) => (
                    <TextField {...params} label={intl.formatMessage({ id: "select.techinical_team" })} />
                  )}
                />
              </Grid>


            
                <Grid item xs={11.3} >
                  <Typography variant="h5" style={styles.header}>
                    Associar Técnicos
                  </Typography>
                </Grid>
                <Grid item xs={0.7} >
                  <SimpleHelp message={this.props.intl.formatMessage({ id: "only.enable.users" })} />

                </Grid >

              <Grid item xs={12} sm={12} style={{paddingTop:'0px'}}>
                <SimpleCheckBox label={intl.formatMessage({ id: "view.all.techs" })} style={{marginTop:'0px'}} name='ViewAllTechs' stateName='ViewAllTechs' changeSelect={this.handleChangeCheckbox} selected={this.state.ViewAllTechs} />
              </Grid>

              
              <Grid item xs={12} sm={12} style={{  marginBottom: '10px' }} >
                {/*<UserAssociation TechinicalTeamId={this.state.channel.TechinicalTeamId} />*/}


                <Autocomplete
                  value={null}
                  inputValue={this.state.techInput}
                  onInputChange={this.handleInputChangeTech}
                  options={this.state.techinicalList

                  }
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => value && this.handleTechnicianSelect(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Técnicos" variant="outlined" fullWidth />
                  )}
                  filterOptions={this.filterOptions}

                />

                <Paper elevation={3} style={{ marginTop: '30px', marginBottom: '16px' }}>
                  <DragDropContext onDragEnd={this.handleOrderChange}>
                    <Droppable droppableId="technicians">
                      {(provided) => (
                        <TableContainer ref={provided.innerRef} {...provided.droppableProps}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Nome do Técnico</TableCell>
                                <TableCell>Ordem</TableCell>
                                <TableCell>Excluir</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedTechnicians.map((technician, index) => (
                                <Draggable key={technician.id} draggableId={technician.id.toString()} index={index}>
                                  {(provided) => (
                                    <TableRow
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <TableCell>{technician.name}</TableCell>
                                      <TableCell>{technician.order}</TableCell>
                                      <TableCell>
                                        <IconButton

                                          onClick={() => this.handleTechnicianDelete(technician, index)}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Paper>

              </Grid>


              <Grid item xs={12} sm={12}>
                <SimpleAutoComplete
                  name="DistributionStrategyId"
                  label={intl.formatMessage({ id: "select.distribution_strategy" })}
                  options={this.state.optionsDistributionsStrategies}
                  stateName='DistributionStrategyId'
                  changeSelect={(stateName, value) => {
                    this.handleChangeAutoComplete(stateName, value)
                  }}
                  selected={this.state.channel.DistributionStrategyId}
                  htmlOptions={true}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <SimpleAutoComplete
                  name="ServiceRules"
                  label={intl.formatMessage({ id: "select.service_rules" })}
                  options={this.state.optionsServiceRules}
                  stateName='ServiceRulesId'
                  changeSelect={(stateName, value) => {
                    this.handleChangeAutoComplete(stateName, value)
                  }}
                  selected={this.state.channel.ServiceRulesId}
                  htmlOptions={true}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <SimpleAutoComplete 
                  label={intl.formatMessage({ id: "organization" })} 
                  options={this.state.organizationsList} 
                  stateName="DefaultOrganizationId" 
                  selected={this.state.channel.DefaultOrganizationId} 
                  changeSelect={(stateName, value) => {
                    this.handleChangeAutoComplete(stateName, value)
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <SimpleAutoComplete 
                  label={intl.formatMessage({ id: "category" })} 
                  options={this.state.categoryList} 
                  stateName='DefaultOrganizationCategoryId' 
                  selected={this.state.channel.DefaultOrganizationCategoryId} 
                  changeSelect={(stateName, value) => {
                    this.handleChangeAutoComplete(stateName, value)
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Switch
                  value={this.state.channel.Published}
                  checked={this.state.channel.Published}
                  name="Published"
                  onChange={(e) => this.handleChangeSwitch(e)}
                />
                <FormattedMessage id="published" />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Switch
                  value={this.state.channel.Active}
                  checked={this.state.channel.Active}
                  name="Active"
                  onChange={(e) => this.handleChangeSwitch(e)}
                />
                <FormattedMessage id="active" />
              </Grid>

              

              <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={12} sm={4}    >
                    <Button
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                      onClick={this.handleSubmit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </form>
        </div>


        {this.state.loading && (
          <Overlay>
            <CircularProgress color='secondary' />
          </Overlay>
        )}

        {
          this.state.openNotification && (
            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          )
        }
        <DialogGeneral
          dialogTitle={intl.formatMessage({ id: "team.change.confirmation" })}
          dialogSubTitle={intl.formatMessage({ id: "change.team.association.info" })}
          open={this.state.dialogState}
          openOrCloseModalFunction={this.handleCancel}
          handleConfirmation={this.handleTeamChange}

        />
        <DialogGeneral
          dialogTitle={this.props.intl.formatMessage({ id: "remove.tech.association" })}
          dialogSubTitle={this.props.intl.formatMessage({ id: "remove.tech.association.info" })}
          open={this.state.DialogRemoveState}
          openOrCloseModalFunction={this.openOrCloseRemoveModal}
          handleConfirmation={this.handleDeleteOption}
        />

      </div>
    )
  }
}

RegistrationData.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  channels: state.channels
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(RegistrationData)));

RegistrationData.contextType = AbilityContext;