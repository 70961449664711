import { Button, Grid, TextField, Tooltip, Typography } from "@mui/material";
import React, { Component } from "react";
import PropTypes from 'prop-types'
import SimpleCheckBox from "src/components/checkbox/check";
import * as Service from '../../../services/config.service';
import DialogConfirmationDeleteProvider from '../../../components/dialog-confirmation-not-button'
import { ContentCopy, Delete, Help } from "@mui/icons-material";
import { connect } from "react-redux";
import Api from "src/services/api";
import { PapiroConsole } from "src/utils/papiroConsole";
import SimpleAutoComplete from "src/components/auto-complete/autocomplete-dialog-with-z-index";
import { FormattedMessage } from "react-intl";
import SimpleHelp from "src/components/help";

class EmailConfigTab extends Component {
    constructor(props) {
        super(props)
        const { config, OrgsList } = this.props
        this.state = {
            config: {
                id: config.id,
                frommail: config.frommail == null ? '' : config.frommail,
                hostmail: config.hostmail == null ? '' : config.hostmail,
                portmail: config.portmail == null ? '' : config.portmail,
                usermail: config.usermail == null ? '' : config.usermail,
                passwordmail: "",
                mailtest: "",
                enablessl: config.enablessl == null ? false : config.enablessl,
                usemexxdefaultemailprovider: config.usemexxdefaultemailprovider ? config.usemexxdefaultemailprovider : false,
                frommailreceive: config.frommailreceive,
                emailorganizationid: config.emailorganizationid ? config.emailorganizationid : { id: 0, name: "" },
                accompanimentcalledforresponseemail: config.accompanimentcalledforresponseemail,
                responseemailisnewcalled: config.responseemailisnewcalled
            },
            OrgsList: OrgsList ? OrgsList : [],
            openDeleteProviderUserDialog: false
        }

        this.changeValues = this.changeValues.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.checkProviderDeletion = this.checkProviderDeletion.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState(prevState => ({
            config: {
                ...prevState.config,
                [name]: value
            }
        }));
    }

    changeValues(stateName, value) {
        this.setState(prevState => ({
            config: {
                ...prevState.config,
                [stateName]: value
            }
        }));
    }

    handleClick = async (e) => {

        let { intl, setLoading, setMessage } = this.props
        setLoading(true)

        if (this.state.config.mailtest.length > 0) {
            let result = await Service.testMail(this.state.config.mailtest);
            if (result && result.data && result.data.success == true) {
                setLoading(false)
                setMessage(true, 'success', intl.formatMessage({ id: "email.sent" }))
            } else {
                const error = result && result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                setLoading(false)
                setMessage(true, 'error', error)
                Api.kickoff(result)
            }
        } else {
            setLoading(false)
            setMessage(true, 'error', intl.formatMessage({ id: "missing.destination.email" }))
        }
    }

    checkProviderDeletion = (e) => {
        e.preventDefault();
        if (this.props.config.usemexxdefaultemailprovider != this.state.config.usemexxdefaultemailprovider && this.state.config.usemexxdefaultemailprovider == false) {
            this.setState({
                openDeleteProviderUserDialog: true
            })
        } else {
            this.handleSubmit(e)
        }
    }

    handleSubmit = async => {

        let { intl, setLoading, setMessage } = this.props

        if (this.state.config.responseemailisnewcalled === true && this.state.config.accompanimentcalledforresponseemail === true) {
            setMessage(true, 'error', intl.formatMessage({ id: "organization.account.email.registration.email.answer.is.new.called" }))
        } else {
            setLoading(true)

            let data = new FormData()

            for (let key in this.state.config) {
                if (key.toString().toLowerCase().indexOf('emailorganization') > -1) {
                    if (this.state.config[key] && this.state.config[key].id) {
                        data.append(key, this.state.config[key].id);
                    }
                    else {
                        data.append(key, 0);
                    }
                } else {
                    data.append(key, this.state.config[key]);
                }
            }
            let apiConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            Api.put('/configs/emailconfig', data, apiConfig)
                .then(result => {
                    if (result.data.success) {
                        const formattedList = result.data.data;
                        const intl = this.props.intl;
                        setLoading(false)
                        setMessage(true, 'success', intl.formatMessage({ id: "edit.success" }))
                    }
                    else {
                        const error = result && result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        setLoading(false)
                        setMessage(true, 'error', error)
                    }
                })
                .catch(err => {
                    if (err.response && err.response.data && err.response.data.errors) {
                        const error = err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        setLoading(false)
                        setMessage(true, 'error', error)
                    }
                    else {
                        const error = err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }) + err.response.data
                        setLoading(false)
                        setMessage(true, 'error', error)
                    }
                })
        }
    }

    render() {
        const { classes, intl } = this.props
        const { config } = this.state

        PapiroConsole.log(config)
        PapiroConsole.log("config")

        return (
            <div>
                <form name='myForm' className={classes.form} onSubmit={this.checkProviderDeletion} encType='multipart/form-data'>
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12}>
                            <Typography component="h1" variant="h5" style={{ color: "#263238", fontSize: "16px" }}>
                                {intl.formatMessage({ id: "mail.sending" })}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="frommail"
                                variant="outlined"
                                type="email"
                                fullWidth
                                id="frommail"
                                label={intl.formatMessage({ id: "config.edit.email.from" })}
                                value={config.frommail}
                                onChange={this.handleInputChange} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="hostmail"
                                variant="outlined"
                                fullWidth
                                id="hostmail"
                                label={intl.formatMessage({ id: "hostmail" })}
                                value={config.hostmail}
                                onChange={this.handleInputChange} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="portmail"
                                variant="outlined"
                                type="number"
                                fullWidth
                                id="portmail"
                                label={intl.formatMessage({ id: "port" })}
                                value={config.portmail}
                                onChange={this.handleInputChange} />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="usermail"
                                variant="outlined"
                                fullWidth
                                id="usermail"
                                label={intl.formatMessage({ id: "user.access" })}
                                value={config.usermail}
                                onChange={this.handleInputChange} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="passwordmail"
                                variant="outlined"
                                fullWidth
                                id="passwordmail"
                                label={intl.formatMessage({ id: "access.password" })}
                                type='password'
                                placeholder={intl.formatMessage({ id: "leave.empty" })}
                                value={config.passwordmail}
                                onChange={this.handleInputChange} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <SimpleCheckBox
                                label={intl.formatMessage({ id: "config.edit.enable.ssl" })}
                                name='enablessl'
                                stateName='enablessl'
                                changeSelect={this.changeValues}
                                selected={config.enablessl} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="mailtest"
                                variant="outlined"
                                fullWidth
                                id="mailtest"
                                label={intl.formatMessage({ id: "config.edit.label.send.test.email" })}
                                value={config.mailtest}
                                onChange={this.handleInputChange} />
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Grid container justify="flex-start" spacing={2} style={{ justifyContent: 'flex-start' }}>
                                <Grid item xs={12} sm={6} align-items-xs-center={'true'}>
                                    <Button
                                        onClick={this.handleClick}
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        className={classes.submit} >
                                        {intl.formatMessage({ id: "config.edit.test.sending.email" })}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography component="h1" variant="h5" style={{ color: "#263238", fontSize: "16px" }}>
                                {intl.formatMessage({ id: "mail.reading" })}
                            </Typography>
                        </Grid>
                        <Grid item xs={11.5} sm={11.5}>
                            <SimpleCheckBox
                                label={intl.formatMessage({ id: 'usemexxdefaultemailprovider' })}
                                name='usemexxdefaultemailprovider'
                                stateName='usemexxdefaultemailprovider'
                                changeSelect={this.changeValues}
                                selected={config.usemexxdefaultemailprovider}
                            />
                        </Grid>
                        <Grid item xs={0.5} sm={0.5} style={{ textAlign: "middle", alignSelf: "stretch", marginTop: '10px' }} >
                            <Tooltip title={<h5>{intl.formatMessage({ id: "usemexxdefaultemailprovider.tooltip" })}</h5>}>
                                <Help />
                            </Tooltip>
                        </Grid>
                        {config.usemexxdefaultemailprovider &&
                            <Grid item container spacing={2} xs={12} sm={12}>
                                <Grid item xs={11.5} sm={11.5}>
                                    <TextField label={intl.formatMessage({ id: "email" })} disabled value={config.frommailreceive} fullWidth />
                                </Grid>
                                <Grid item xs={0.5} sm={0.5} style={{ textAlign: "middle", alignSelf: "stretch", marginTop: '10px' }} >
                                    <Tooltip title={<h5>{intl.formatMessage({ id: "copy.emails" })}</h5>} color="secondary" style={{ cursor: "pointer" }}>
                                        <ContentCopy onClick={_ => navigator.clipboard.writeText(config.frommailreceive)} />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={11.5} sm={11.5}>
                                    <SimpleAutoComplete
                                        label={intl.formatMessage({ id: "email.org.default" })}
                                        options={this.state.OrgsList}
                                        stateName='emailorganizationid'
                                        changeSelect={this.changeValues}
                                        selected={config.emailorganizationid}
                                    />
                                </Grid>
                                <Grid item xs={0.5} sm={0.5} style={{ textAlign: "middle", alignSelf: "stretch", marginTop: '10px' }} >
                                    <Tooltip title={<h5>{intl.formatMessage({ id: "email.org.default.tooltip" })}</h5>}>
                                        <Help />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={11.5} sm={11.5}>
                                    <SimpleCheckBox label={intl.formatMessage({ id: 'ResponseEmailIsNewCalled' })} name='responseemailisnewcalled' stateName='responseemailisnewcalled' changeSelect={this.changeValues} selected={config.responseemailisnewcalled} />
                                </Grid>
                                <Grid item xs={0.5} sm={0.5}>
                                    <Tooltip title={<h5>{intl.formatMessage({ id: "organization.account.email.registration.email.reply.new.called" })}</h5>}>
                                        <Help />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={11.5} sm={11.5}>
                                    <SimpleCheckBox label={intl.formatMessage({ id: 'AccompanimentCalledForResponseEmail' })} name='accompanimentcalledforresponseemail' stateName='accompanimentcalledforresponseemail' changeSelect={this.changeValues} selected={config.accompanimentcalledforresponseemail} />
                                </Grid>
                                <Grid item xs={0.5} sm={0.5}>
                                    <Tooltip title={<h5>{intl.formatMessage({ id: "organization.account.email.registration.email.reply.new.accompaniment" })}</h5>}>
                                        <Help />
                                    </Tooltip>
                                </Grid>
                            </Grid>}
                        <Grid item xs={12} sm={12} style={{ marginTop: 50, display: 'flex', justifyContent: 'flex-end' }}>
                            <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                                <Grid item xs={12} sm={4}    >
                                    <Button
                                        type='submit'
                                        fullWidth
                                        variant='contained'
                                        color='primary'
                                        className={classes.submit}
                                    >
                                        {<FormattedMessage id="save" />}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                {this.state.openDeleteProviderUserDialog && (
                    <DialogConfirmationDeleteProvider
                        msgButton={intl.formatMessage({ id: "confirm" })}
                        msg={intl.formatMessage({ id: "delete.provider.usermail" })}
                        msgDisagree={intl.formatMessage({ id: "cancel" })}
                        msgAgree={intl.formatMessage({ id: "yes" })}
                        handleConfirmation={_ => this.handleSubmit()}
                        handleClose={_ => this.setState({ openDeleteProviderUserDialog: false })}
                        classes={classes}
                        disabled={false}
                        icon={<Delete />}
                    />
                )}
            </div>
        )
    }
}

EmailConfigTab.propTypes = {
    classes: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    setMessage: PropTypes.func.isRequired,
    orgslist: PropTypes.func.isRequired
}

export default connect(null, null)(EmailConfigTab)