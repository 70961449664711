import VpnKeyIcon from '@mui/icons-material/VpnKey'
import { Avatar, Button, CircularProgress, Container, CssBaseline, FormControlLabel, Grid, Switch, Tab, Tabs, TextField, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import 'react-quill/dist/quill.snow.css'; // ES6
import { connect } from 'react-redux'
import styled from 'styled-components'
import Footer from '../../components/footer'
import HeaderMenu from '../../components/header-menu'
import CustomizedSnackbars from '../../components/material-snackbars'
import Title from '../../components/title-name'
import { browserHistory } from '../../helpers/history'
import Api from '../../services/api'
import * as IntegrationServiceService from "../../services/integrationservice.service"
import { Overlay } from '../../styles/global'
import { Styles } from '../../styles/material-styles'
import { PapiroConsole } from '../../utils/papiroConsole'

import { Radio, RadioGroup } from '@mui/material'

import SimpleAutoComplete from '../../components/auto-complete/autocomplete-dialog-with-z-index'
import EditableTable from '../authentication-registration/components/editable-table'
import EditableTableService from '../integration-service-edit/components/editable-table-service'

const StyledTextField = styled(TextField)`
  width: 100%;
`;

class IntegrationServiceRegistration extends Component {

  constructor(props) {
    super(props)
    const { userSession } = this.props

    this.globalAdm = false
    this.isClient = true

    if (userSession.user.userrole == null) {
      Api.logoff()
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype !== 3) {
        this.isClient = false
      }
      if (item.role.roletype === 0) {
        this.globalAdm = true
      }
    })

    this.initialState = {
      name: '',
      url: '',
      method: { id: 0, name: '' },
      integrationAuth: { id: 0, name: '' },
      methodAuth: {id : 0, name:''},
      requestBodyType: '1',
      objectName: '',
      nameErrorField: '',
      valueErrorField: '',
      messageError: '',
      nameFieldDisplay: '',
      variableIdFieldDisplay: { id: 0, name: '' },
      nameFieldValue: '',
      variableIdFieldValue: { id: 0, name: '' },
      isArrayObjectType: false,
      errorFieldReturnType: null,
      errorDataFieldName: '',
      notFoundErrorMessage: ''
    }

    this.state = {
      item: this.initialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      methodList: [],
      integrationAuthList: [],
      methodAuthList: [],
      tabIndex: 0,
      paramRows: [{ id: 1, name: '', value: '', isPathParam: false, isEditable: true }],
      headerRows: [{ id: 1, name: '', value: '', isEditable: true }],
      formDataRows: [{ id: 1, name: '', value: '', isEditable: true }],
      jsonValue: '',
      jsonError: '',
      associationList : []
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.closeNotification = this.closeNotification.bind(this)
    this.changeValues = this.changeValues.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount()
    }
  }

  async componentDidMount() {
    await this.getData();
  }

  async getData() {
    this.setState({ loading: true })
    const result = await IntegrationServiceService.getData()
    if (result.success) {
      var data = result.data
      this.setState({
        loading: false, 
        methodList: data.methodintegration, 
        integrationAuthList: data.integrationauth, 
        associationList: data.associationlist,
        methodAuthList: data.authenticationmethodintegration
      })
    }
    else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  handleInputChange = e => {
    const name = e.target.name
    const value = e.target.value

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }))
  }

  validations(data) {
    var returnStatus = true;

    Object.keys(data).forEach(element => {
      PapiroConsole.log(element)
      if (element == 'name') {
        if (data[element] === null || data[element] == '') {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: "É necessário o preenchimento do campo 'Nome'",
          })

          return false;
        }
      }
      if (element == 'url') {
        if (data[element] === null || data[element] == '') {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: "É necessário o preenchimento do campo 'Url'",
          })
          return false;

        }
      }
      if (element == 'method') {

        if (data[element] === null || data[element].id == 0) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: "É necessário o preenchimento do campo 'Método de chamada'",
          })

          return false;
        }

      }
      if (element == 'integrationAuth') {

        if (data[element] === null || data[element].id == 0) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: "É necessário o preenchimento do campo 'Autenticação'",
          })

          return false;
        }
      }
    });

    return returnStatus;
  }

  handleSubmit = async e => {
    e.preventDefault()

    this.setState({ loading: true })

    const formData = Object.assign({}, this.state.item);

    var filteredListParams = []
    var filteredListHeaders = []
    var filteredListFormData = []

    if (this.state.paramRows) {
      filteredListParams = this.state.paramRows.filter(item => !item.isEditable);
    }
    if (this.state.headerRows) {
      filteredListHeaders = this.state.headerRows.filter(item => !item.isEditable);
    }

    if (this.state.formDataRows) {
      filteredListFormData = this.state.formDataRows.filter(item => !item.isEditable);
    }

    formData.ParamRows = filteredListParams;
    formData.HeaderRows = filteredListHeaders;
    formData.BodyRequestRows = filteredListFormData;
    formData.jsonValue = this.state.jsonValue;

    if (this.validations(formData)) {
      formData.method = formData.method.id
      formData.integrationAuthId = formData.integrationAuth.id
      formData.methodAuth = formData.methodAuth.id
      formData.variableIdFieldDisplay = formData.variableIdFieldDisplay.relationid
      formData.variableIdFieldValue = formData.variableIdFieldValue.relationid
      formData.errorFieldReturnType = formData.errorFieldReturnType.id

      console.log("@log >> payload >> ", formData);

      var result = await IntegrationServiceService.addItem(formData)
      if (result && result.success) {
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'success',
          notificationMessage: intl.formatMessage({ id: "add.success" }),
          item: this.initialState,
          tabIndex: 0,
          paramRows: [
            { id: 1, name: '', value: '', isPathParam: false, isEditable: true }
          ],
          headerRows: [
            { id: 1, name: '', value: '', isEditable: true }
          ],
          formDataRows: [
            { id: 1, name: '', value: '', isEditable: true }
          ],
          jsonValue: '',
          jsonError: '',
        })


      } else {
        const intl = this.props.intl
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(result)
      }
    }
  }

  closeNotification() {
    this.setState({ openNotification: false })
  }

  changeValues(stateName, value, text = '') {
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }))
  }

  handleJsonValueChange = (e) => {
    const { value } = e.target;
    this.setState({ jsonValue: value });
    this.validateJson(value);
  };

  validateJson = (value) => {
    try {
      JSON.parse(value);
      this.setState({ jsonError: '' }); // Se o JSON for válido, limpa a mensagem de erro
    } catch (error) {
      this.setState({ jsonError: 'JSON inválido' }); // Se o JSON for inválido, define a mensagem de erro
    }
  };

  handleDeleteRow = (type, rowParam) => {
    this.setState(prevState => ({
      [type]: prevState[type].filter(row => row.id !== rowParam.id)
    }));
  };

  handleAddRow = (type, id) => {
    const row = this.state[type].find(row => row.id === id);

    if (row.name) {
      this.setState(prevState => ({
        [type]: prevState[type].map(row => (row.id === id ? { ...row, isEditable: false } : row)).concat({ id: Math.max(...prevState[type].map(r => r.id)) + 1, name: '', value: '', isEditable: true, ...(type === "paramRows" ? { isPathParam: false } : {}) }),
      }));
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "Preencha todos os campos antes de adicionar."
      })
    }
  };

  handleInputChangeTable = (type, field, value, id) => {
    this.setState(prevState => ({
      [type]: prevState[type].map(row => (row.id === id ? { ...row, [field]: value } : row))
    }));
  };

  handleChangeAutoCompleteTable = (type, value, id, field) => {
    this.setState(prevState => ({
      [type]: prevState[type].map(row => (row.id === id ? { ...row, [field]: value } : row))
    }));
  };

  handleTabChange = (e, newValue) => {
    this.setState({ tabIndex: newValue });
  };

  handleRequestBodyTypeChange = (e) => {
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        requestBodyType: e.target.value
      },
      jsonError: ''
    }))
  };

  handleChangeSwitch(event) {
    const stateName = event.target.name;
    const value = event.target.checked;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;
    const { tabIndex, paramRows, headerRows, formDataRows, jsonValue, jsonError } = this.state;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}>
          <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar style={{ backgroundColor: '#3f51b4' }} >
                <VpnKeyIcon />
              </Avatar>
              <Typography component='h1' variant='h5' style={{ marginTop: '10px' }}>
                {<FormattedMessage id="add.item" />}{<FormattedMessage id="integration.service" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({ id: "name" })} variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete label={intl.formatMessage({ id: "authentication.method" })} options={this.state.methodAuthList} stateName='methodAuth' changeSelect={this.changeValues} selected={this.state.item.methodAuth} required />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete label={intl.formatMessage({ id: "authentication" })} options={this.state.integrationAuthList} stateName='integrationAuth' changeSelect={this.changeValues} selected={this.state.item.integrationAuth} required />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({ id: "url" })} variant='outlined' name='url' onChange={this.handleInputChange} value={item.url} required selected={this.state.item.url} />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete label={intl.formatMessage({ id: "call.method" })} options={this.state.methodList} stateName='method' changeSelect={this.changeValues} selected={this.state.item.method} required />
                  </Grid>

                  <Grid item xs={12} sm={12} >
                    <TextField inputProps={{ maxLength: 255 }} fullWidth label="Nome do objeto origem" autoComplete='fname' variant='outlined' name='objectName' onChange={this.handleInputChange} value={item.objectName} />
                  </Grid>    

                  <Grid item xs={12} sm={12} >
                    <TextField inputProps={{ maxLength: 255 }} fullWidth label="Nome do campo indicativo de erro" autoComplete='fname' variant='outlined' name='nameErrorField' onChange={this.handleInputChange} value={item.nameErrorField} />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete 
                      label="Tipo de retorno do campo indicativo de erro"
                      options={[
                        {id: 1, name: "BOOLEANO"},
                        {id: 2, name: "INTEIRO"},
                        {id: 3, name: "STRING"},
                        {id: 3, name: "ARRAY"},
                      ]} 
                      stateName='errorFieldReturnType' 
                      changeSelect={this.changeValues} 
                      selected={this.state.item.errorFieldReturnType} 
                      required 
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} >
                    <TextField 
                      inputProps={{ maxLength: 255 }} 
                      fullWidth 
                      label="Valor do campo de erro que indica que o erro ocorreu" 
                      autoComplete='fname' 
                      variant='outlined' 
                      name='valueErrorField' 
                      onChange={this.handleInputChange} 
                      value={item.valueErrorField} 
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} >
                    <TextField inputProps={{ maxLength: 255 }} fullWidth label="Nome do campo que terá os dados de erro" autoComplete='fname' variant='outlined' name='errorDataFieldName' onChange={this.handleInputChange} value={item.errorDataFieldName} />
                  </Grid>

                  <Grid item xs={12} sm={12} >
                    <TextField multiline rows={4} fullWidth label="Mensagem que será enviada, em caso de erro" variant='outlined' name='messageError' onChange={this.handleInputChange} value={item.messageError} />
                  </Grid> 

                  <Grid item xs={12} sm={12} >
                    <TextField 
                      multiline 
                      rows={4} 
                      fullWidth 
                      label="Mensagem que será enviada, em caso do objeto de retorno não ser encontrado" 
                      variant='outlined' 
                      name='notFoundErrorMessage' 
                      onChange={this.handleInputChange} 
                      value={item.notFoundErrorMessage} 
                    />
                  </Grid>   

                  <Grid item xs={12} sm={12}>
                    <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({ id: "Nome do campo de valor" })} variant='outlined' name='nameFieldValue' onChange={this.handleInputChange} value={item.nameFieldValue} required />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete 
                      label={intl.formatMessage({ id: "Associação campo de valor" })} 
                      options={this.state.associationList?.filter(item => item.type === "variable")} 
                      stateName='variableIdFieldValue' 
                      changeSelect={this.changeValues} 
                      selected={this.state.item.variableIdFieldValue} 
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({ id: "Nome do campo de exibição" })} variant='outlined' name='nameFieldDisplay' onChange={this.handleInputChange} value={item.nameFieldDisplay} required />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete 
                      label={intl.formatMessage({ id: "Associação campo de exibição" })} 
                      options={this.state.associationList?.filter(item => item.type === "variable")} 
                      stateName='variableIdFieldDisplay' 
                      changeSelect={this.changeValues} 
                      selected={this.state.item.variableIdFieldDisplay} 
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Switch
                      value={this.state.item.isArrayObjectType}
                      checked={this.state.item.isArrayObjectType}
                      name="isArrayObjectType"
                      onChange={(e) => this.handleChangeSwitch(e)}
                    />
                    <FormattedMessage id="O retorno é um array ?" />
                  </Grid>  

                  <Grid item xs={12} sm={12}>
                    <Tabs style={{ marginTop: '10px' }} value={tabIndex} onChange={this.handleTabChange}>
                      <Tab label="Parâmetros de consulta" />
                      <Tab label="Cabeçalho" />
                      <Tab label="Corpo da Requisição" />
                    </Tabs>

                    {tabIndex === 0 && (
                      <EditableTableService rows={paramRows} handleDeleteRow={this.handleDeleteRow} handleAddRow={this.handleAddRow} handleInputChangeTable={this.handleInputChangeTable} type="paramRows" associationList = {this.state.associationList} handleChangeAutoCompleteTable={this.handleChangeAutoCompleteTable}/>
                    )}

                    {tabIndex === 1 && (
                      <EditableTable rows={headerRows} handleDeleteRow={this.handleDeleteRow} handleAddRow={this.handleAddRow} handleInputChangeTable={this.handleInputChangeTable} type="headerRows"  />
                    )}

                    {tabIndex === 2 && (

                      <Grid item xs={12}>
                        <RadioGroup
                          value={item.requestBodyType}
                          onChange={this.handleRequestBodyTypeChange}
                          sx={{ display: 'flex', flexDirection: 'row' }}
                        >
                          <FormControlLabel value="1" control={<Radio />} label="Nenhum" />
                          <FormControlLabel value="2" control={<Radio />} label="Form-data" />
                          <FormControlLabel value="3" control={<Radio />} label="JSON" />
                        </RadioGroup>

                        {item.requestBodyType === '2' && (
                          <EditableTableService handleDeleteRow={this.handleDeleteRow} handleAddRow={this.handleAddRow} rows={formDataRows} handleInputChangeTable={this.handleInputChangeTable} type="formDataRows" associationList = {this.state.associationList} handleChangeAutoCompleteTable={this.handleChangeAutoCompleteTable} />
                        )}

                        {item.requestBodyType === '3' && (
                          <div>
                            <StyledTextField
                              multiline
                              rows={10}
                              value={jsonValue}
                              onChange={this.handleJsonValueChange}
                              placeholder="Digite seu JSON aqui"
                              error={!!jsonError} // Define o estado de erro
                              helperText={jsonError} // Exibe a mensagem de erro
                            />
                          </div>
                        )}

                      </Grid>

                    )}
                  </Grid>

                  <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4}    >
                        <Button
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit} >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>
            {
              this.state.loading && (
                <Overlay>
                  <CircularProgress color='secondary' />
                </Overlay>
              )
            }
            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />



          </Container >
        </main >
        <Footer />
      </div >
    )
  }
}

IntegrationServiceRegistration.propTypes = { classes: PropTypes.object.isRequired };

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  organizations: state.organizations,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(IntegrationServiceRegistration)));